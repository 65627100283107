import { useContext } from 'react'
import { ProcessesConfigContext } from '../context/ProcessesConfigContext'
import { processesConfigService } from '../services/processesConfigService';

export function useProcessesConfig () {
    const { configSubSections, setConfigSubSections, currentConfig, setCurrentConfig } = useContext(ProcessesConfigContext)
    const { getCreditRiskProcessesConfig, putCreditRiskProcessesConfig, putEpigraphsConfig } = processesConfigService();


    const getSubSectionCurrentConfig = (subSectionName) => {
        return currentConfig[subSectionName]
    }

    const fetchCreditRiskProcessesConfig = async () => {
        const data = await getCreditRiskProcessesConfig();
        if (data) {
            setConfigSubSections(data.sub_sections);
            setCurrentConfig(data.current_values)
        }
    };

    const updateCreditRiskProcessesConfig = async (subSectionName, newConfigValues) => {
        const newConfig = {...currentConfig, [subSectionName]: newConfigValues};
        const auxNewConfig = {...newConfig};
        delete auxNewConfig["Relación epígrafes importe"];
        const data = await putCreditRiskProcessesConfig(auxNewConfig);
        if (data) {
            setCurrentConfig(newConfig);
        }
        return data;
    };

    const updateCreditRiskEpigraphsConfig = async (subSectionName, newConfigValues) => {
        const data = await putEpigraphsConfig({[subSectionName]: newConfigValues});
        if (data) {
            const newConfig = {...currentConfig, [subSectionName]: newConfigValues};
            setCurrentConfig(newConfig);
        }
        return data;
    };

  return { configSubSections, currentConfig, fetchCreditRiskProcessesConfig, updateCreditRiskProcessesConfig, updateCreditRiskEpigraphsConfig, getSubSectionCurrentConfig }
}