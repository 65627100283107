import { React, useState, useEffect } from 'react';
import { Box, Collapse, Paper, Stack, Table, TableBody, IconButton,
         TableContainer, TableHead, Typography, FormControl, MenuItem, Select, Pagination, Tabs } from '@mui/material';
import { Edit, CheckCircle as CheckIcon } from '@mui/icons-material';
import ExpandIcon from '../../components/ExpandIcon';
import LoadingSpinner from '../../components/LoadingSpinner';
import InLineError from '../../components/InLineError';
import { AntTab } from '../../components/CustomStyles';
import { StyledTableCell, StyledTableRow } from '../../components/CustomStyles';
import { useResponsive } from '../../hooks/useResponsive';
import { useProcesses } from '../../hooks/useProcesses';
import { useErrors } from '../../hooks/useErrors';
import { InputFilesTable } from './InputFilesTable';
import { OutputFilesTable } from './OutputFilesTable';
import { CommentsTable } from './CommentsTable';


const possibleSituationsList = [
    'Sin revisar',
    'En revisión',
    'Validado',
    'Descartado'
]

const tabNames = [
    'Ficheros de entrada',
    'Ficheros de salida',
    'Comentarios'
]


const Row = (props) => {
    const { row } = props;
    const { handleOnChangeSituation, updateProcessSituation } = useProcesses()
    const [tab, setTab] = useState(0);
    const [open, setOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    const handleOnClick = () => setOpen(!open);

    const alternateEdit = () => setIsEditing(!isEditing);

    const saveEditSituation = async () => { 
        updateProcessSituation({ situation: row.situation, process_id: row.process_id, })
        alternateEdit();
    };

    const handleChangeTab = (_event, value) => {
        setTab(value);
    };

    const removeMilliseconds = (datetimeString) => {
        const lastDotIndex = datetimeString.lastIndexOf('.');
        if (lastDotIndex === -1) {
            return datetimeString;
        }
        const result = datetimeString.substring(0, lastDotIndex);
        return result;
    };


    return (
        <>
            <StyledTableRow>
                <StyledTableCell sx={{ pr: '11px' }}>
                    <ExpandIcon
                        size={'small'}
                        open={open}
                        onClick={handleOnClick}
                    />
                </StyledTableCell>
                <StyledTableCell>{row.data_date}</StyledTableCell>
                <StyledTableCell>{removeMilliseconds(row.execution_date)}</StyledTableCell>
                <StyledTableCell>{row.user}</StyledTableCell>
                <StyledTableCell>{row.process_type}</StyledTableCell>
                <StyledTableCell>{row.version}</StyledTableCell>
                <StyledTableCell>{row.state}</StyledTableCell>
                <StyledTableCell>{row.duration}</StyledTableCell>
                {isEditing ?
                    <StyledTableCell>
                        <FormControl style={{width: '70%'}}>
                            <Select
                                value={row.situation}
                                onChange={(event) => handleOnChangeSituation(row.process_id, event.target.value)}
                                size='small'
                                color='primary'
                                sx={{ fontSize: '12px', fontWeight: 400, height: '25px', backgroundColor: 'white' }}
                            >
                                {possibleSituationsList.map(elem => (
                                    <MenuItem
                                        key={elem}
                                        value={elem}
                                        sx={{ fontSize: '12px', fontWeight: 400 }}
                                    >
                                        {elem}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </StyledTableCell>
                :
                    <StyledTableCell>{row.situation}</StyledTableCell>
                }
                <StyledTableCell>
                    <IconButton size={'small'} onClick={isEditing ? saveEditSituation : alternateEdit}>
                        {isEditing ? <CheckIcon fontSize='small' /> : <Edit fontSize='small' />}
                    </IconButton>
                </StyledTableCell>
            </StyledTableRow>

            <StyledTableRow>
                <StyledTableCell sx={{ backgroundColor: 'white' }}> </StyledTableCell>
                <StyledTableCell colSpan={8} sx={{ backgroundColor: 'white' }}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box>
                            <Tabs 
                                sx={{mb: '5px', minHeight: "38px", height: "38px"}}
                                value={tab}
                                onChange={handleChangeTab}
                            >
                                {tabNames.map((tabName, index) =>
                                    <AntTab
                                        key={index}
                                        tab={index}
                                        label={tabName}
                                    />
                                )}
                                
                            </Tabs>
                        </Box>
                    </Collapse>
                </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
                <StyledTableCell sx={{ backgroundColor: 'white' }}> </StyledTableCell>
                {tab === 0 &&
                    <StyledTableCell colSpan={8} sx={{ backgroundColor: 'white' }}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ml: '7px'}}>
                                <InputFilesTable
                                    row={row} 
                                />
                            </Box>
                        </Collapse>
                    </StyledTableCell>
                }
                {tab === 1 &&
                    <StyledTableCell colSpan={2} sx={{ backgroundColor: 'white' }}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ml: '7px'}}>
                                <OutputFilesTable
                                    row={row} 
                                />
                            </Box>
                        </Collapse>
                    </StyledTableCell>
                }
                {tab === 2 &&
                    <StyledTableCell colSpan={8} sx={{ backgroundColor: 'white' }}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ml: '7px'}}>
                                <CommentsTable
                                    row={row} 
                                />
                            </Box>
                        </Collapse>
                    </StyledTableCell>
                }
            </StyledTableRow>
        </>
    );
}

const paginationLimits = [1, 5, 10, 20];
export default function ProcessesTable(props) {
    const { processesList, paginationData, handleOnChangeSelectedPaginationLimit, loadProcesses, handleChangePage} = useProcesses()
    const { isBelowXlScreen } = useResponsive();
    const { loading, inLineErrors } = useErrors();

    useEffect(() => {
        loadProcesses();
    }, []);

    if (loading.getProcesses) {
        return (
            <LoadingSpinner />
        );
    } else if (inLineErrors.getProcesses) {
        return (
            <InLineError width={"100%"} length={"100%"} errorMessage={inLineErrors.getProcesses} />
        );
    } else {
        return (
            <>
                <TableContainer component={Paper} sx={{ overflowX: isBelowXlScreen ? 'auto' : 'hidden' }}>
                    <Table sx={{ borderCollapse: 'separate', borderSpacing: '0 4px', minWidth: '1250px' }}>
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell width={"2%"} />
                                <StyledTableCell width={"5%"}>Fecha de Datos</StyledTableCell>
                                <StyledTableCell width={"8%"}>Fecha de Ejecución</StyledTableCell>
                                <StyledTableCell width={"8%"}>Usuario</StyledTableCell>
                                <StyledTableCell width={"10%"}>Proceso</StyledTableCell>
                                <StyledTableCell width={"6%"}>Versión Interna</StyledTableCell>
                                <StyledTableCell width={"6%"}>Estado</StyledTableCell>
                                <StyledTableCell width={"5%"}>Duración</StyledTableCell>
                                <StyledTableCell width={"6%"}>Situación</StyledTableCell>
                                <StyledTableCell width={"2%"}></StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {processesList.length > 0 ? processesList.map((processData) => (
                                <Row 
                                    key={processData.process_id} 
                                    row={processData} 
                                />
                            ))
                            :
                            <StyledTableRow style={{ height: "30px"}}>
                                <StyledTableCell colSpan={10}>
                                    <InLineError width={"100%"} length={"100%"} errorMessage={"No se han encontrado resultados."}/>
                                </StyledTableCell>
                            </StyledTableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <Stack direction="row" spacing={2} justifyContent={'space-between'} sx={{ mt: '15px', mb: '15px' }}>
                    <Stack direction="row" spacing={2} justifyContent={'space-between'}>
                        <FormControl>
                            <Select
                                value={paginationData.limit}
                                onChange={handleOnChangeSelectedPaginationLimit}
                                sx={{ height: '32px' }}
                            >
                                {paginationLimits.map(elem => <MenuItem key={elem} value={elem}>{elem}</MenuItem>)}
                            </Select>
                        </FormControl>
                        <Typography sx={{ fontSize: '13px', fontWeight: 400, pt: '8px' }}>
                            {`Mostrando del ${paginationData.count === 0 ? 0 : paginationData.start + 1} 
                            al ${Math.ceil(paginationData.count / paginationData.limit) === paginationData.page ? paginationData.start + processesList.length : paginationData.start + processesList.length} 
                            de ${paginationData.count} ejecuciones`}
                        </Typography>
                    </Stack>
                    <Pagination
                        count={Math.ceil(paginationData.count / paginationData.limit)}
                        shape="rounded"
                        variant='outlined'
                        showFirstButton
                        showLastButton
                        color='primary'
                        page={paginationData.page}
                        onChange={handleChangePage}
                    />
                </Stack>
            </>
        );
    }
}

