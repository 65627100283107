import React from 'react';
import { Stack, Table, TableBody, IconButton, TableHead, TableRow } from '@mui/material';
import { DownloadForOffline } from '@mui/icons-material';
import { StyledTableCell, StyledTableRow } from '../../components/CustomStyles';
import { loadFilesService } from '../../services/loadFilesService';


export const InputFilesTable = (props) => {
    const { row } = props;
    const { getLoadFile } = loadFilesService();

    const downloadInputFile = (file) => {
        const fileDownloadName = file.data_type + '_' + file.file_id + '.zip';
        getLoadFile({ 
            data_type: file.data_type, 
            file_id: file.file_id, 
            month: file.month, 
            year: file.year, 
            fileDownloadName: fileDownloadName 
        });
    };

    return (
        <>
            <Table sx={{marginBottom: '8px'}}>
                <TableHead>
                    <StyledTableRow sx={{ height: 30 }}>
                        <StyledTableCell style={{ fontWeight: 500 }} width={"8%"}>Tipo de fichero</StyledTableCell>
                        <StyledTableCell style={{ fontWeight: 500 }} width={"15%"}>Conjunto de datos</StyledTableCell>
                        <StyledTableCell style={{ fontWeight: 500 }} width={"8%"}>Fecha de importación</StyledTableCell>
                        <StyledTableCell style={{ fontWeight: 500 }} width={"8%"}>Usuario de importación</StyledTableCell>
                        <StyledTableCell style={{ fontWeight: 500 }} width={"1%"}></StyledTableCell>
                    </StyledTableRow>
                </TableHead>
                <TableBody >
                    {row.input_files.map((inputFile) => (
                        <TableRow key={inputFile.file_id} sx={{ height: 30 }}>
                            <StyledTableCell style={{ borderBottom: "1px solid lightgrey" }}>{inputFile.data_type}</StyledTableCell>
                            <StyledTableCell style={{ borderBottom: "1px solid lightgrey" }}>{inputFile.data_set}</StyledTableCell>
                            <StyledTableCell style={{ borderBottom: "1px solid lightgrey" }}>{inputFile.load_date}</StyledTableCell>
                            <StyledTableCell style={{ borderBottom: "1px solid lightgrey" }}>{inputFile.user}</StyledTableCell>
                            <StyledTableCell style={{ borderBottom: "1px solid lightgrey" }}>
                                <Stack direction="row" justifyContent={'center'}>
                                    <IconButton size={'small'} onClick={ () => downloadInputFile(inputFile) }>
                                        <DownloadForOffline fontSize='small' />
                                    </IconButton>
                                </Stack>
                            </StyledTableCell>
                        </TableRow>
                    ))}
                    
                </TableBody>
            </Table>
        </>
    );
}