import * as Sentry from "@sentry/react";
import { useApi } from "../hooks/useApi";
import { useErrors } from "../hooks/useErrors";

export function processesConfigService() {
    const { apiCall } = useApi();
    const { startLoading, stopLoading, setDialogErrorState, clearDialogError } = useErrors();

    const getCreditRiskProcessesConfig = async () => {
        const key = 'getCreditRiskProcessesConfig';
        var fileUrl = new URL(import.meta.env.VITE_API_URL + "/config-credit-processes");
        startLoading(key);
        clearDialogError(key);
        try {
            const response = await apiCall('GET', fileUrl);
            if (response.success) {
                return response.data;
            } else {
                setDialogErrorState(key, "Error encontrando la configuración de procesos de riesgo de crédito.");
                Sentry.captureException(response, {
                    tags: { service: 'processesConfigService', key },
                    contexts: { apiCall: { url: fileUrl.toString(), method: 'GET' } },
                });
                return null;
            }
        } catch (error) {
            setDialogErrorState(key, "Error encontrando la configuración de procesos de riesgo de crédito.");
            Sentry.captureException(error, {
                tags: { service: 'processesConfigService', key },
                contexts: { apiCall: { url: fileUrl.toString(), method: 'GET' } },
            });
            throw error;
        } finally {
            stopLoading(key);
        }
    };

    const putCreditRiskProcessesConfig = async (payload) => {
        const key = 'putCreditRiskProcessesConfig';
        var fileUrl = new URL(import.meta.env.VITE_API_URL + "/config-credit-processes");
        startLoading(key);
        clearDialogError(key);
        try {
            const response = await apiCall('PUT', fileUrl, payload);
            if (response.success) {
                return response.data;
            } else {
                setDialogErrorState(key, "Error al actualizar la configuración de procesos de riesgo de crédito.");
                Sentry.captureException(response, {
                    tags: { service: 'processesConfigService', key },
                    contexts: { apiCall: { url: fileUrl.toString(), method: 'PUT' } },
                });
                return null;
            }
        } catch (error) {
            setDialogErrorState(key, "Error al actualizar la configuración de procesos de riesgo de crédito.");
            Sentry.captureException(error, {
                tags: { service: 'processesConfigService', key },
                contexts: { apiCall: { url: fileUrl.toString(), method: 'PUT' } },
            });
            throw error;
        } finally {
            stopLoading(key);
        }
    };

    const putEpigraphsConfig = async (payload) => {
        const key = 'putEpigrafesConfig';
        var fileUrl = new URL(import.meta.env.VITE_API_URL + "/config-credit-epigraphs");
        startLoading(key);
        clearDialogError(key);
        try {
            const response = await apiCall('PUT', fileUrl, payload);
            if (response.success) {
                return response.data;
            } else {
                setDialogErrorState(key, "Error al actualizar la configuración de procesos de riesgo de crédito sección epígrafes.");
                Sentry.captureException(response, {
                    tags: { service: 'processesConfigService', key },
                    contexts: { apiCall: { url: fileUrl.toString(), method: 'PUT' } },
                });
                return null;
            }
        } catch (error) {
            setDialogErrorState(key, "Error al actualizar la configuración de procesos de riesgo de crédito sección epígrafes.");
            Sentry.captureException(error, {
                tags: { service: 'processesConfigService', key },
                contexts: { apiCall: { url: fileUrl.toString(), method: 'PUT' } },
            });
            throw error;
        } finally {
            stopLoading(key);
        }
    };

    return { getCreditRiskProcessesConfig, putCreditRiskProcessesConfig, putEpigraphsConfig};
}
