import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Grid, TextField } from '@mui/material';
import { Cancel, CheckCircle } from '@mui/icons-material/';
import CustomSelect from '../../components/CustomSelect';
import { BLUE, TITLE_TEXT, SELECT_TEXT } from '../../theme';
import { useProcessesConfig } from '../../hooks/useProcessesConfig';


export default function DropdownConfigDialog ({ subSectionName, open, toggleConfig, dialogDescription, dialogParameters }) {
    const {getSubSectionCurrentConfig, updateCreditRiskProcessesConfig} = useProcessesConfig();
    const [formValues, setFormValues] = useState(getSubSectionCurrentConfig(subSectionName));
    

    const handleChange = (name, value) => {
        setFormValues((prev) => ({
            ...prev,
            [name]: value.toString(),
        }));
    };

    const getSelectItems = (values) => values.map((v) => v.desc);

    const getSelectedValue = (name) => {
        const param = dialogParameters[name];
        const selected = param.values.find((v) => {
            return v.value.toString() === formValues[name]
        }
        );
        return selected ? selected.desc : '';
    };

    const onClickConfirm = () => {
        toggleConfig();
        if (!updateCreditRiskProcessesConfig(subSectionName, formValues)) {
            setFormValues(getSubSectionCurrentConfig(subSectionName));
        }       
    }

    const onClickCancel = () => {
        toggleConfig();
        setFormValues(getSubSectionCurrentConfig(subSectionName));
    }


    return (
        <Dialog
            open={open}
            onClose={toggleConfig}
            maxWidth='lg'
            fullWidth
        >
            <DialogTitle
                color={BLUE}
                sx={TITLE_TEXT}
            >
                {subSectionName}
            </DialogTitle>
            <DialogContent >
                <Typography>{dialogDescription}</Typography>
                <Grid
                    container
                    spacing={2}
                    sx={{ mb: '10px', mt: '8px' }}
                >
                    {Object.entries(dialogParameters).map(([key, param]) => (
                        <Grid item xs={12} sm={6}  key={key}>
                            {param.type === 'enum' ? (
                                <CustomSelect
                                    name={key}
                                    title={param.display_name}
                                    value={getSelectedValue(key)}
                                    onChange={(e) => {
                                        const selectedValue = param.values.find((v) => v.desc === e.target.value)?.value;
                                        handleChange(key, selectedValue);
                                    }}
                                    selectItems={getSelectItems(param.values)}
                                >
                                </CustomSelect>
                            ) : (
                                <>
                                    <Typography sx={{ ...SELECT_TEXT }}>
                                        {param.display_name}
                                    </Typography>
                                    <TextField
                                        InputProps={{
                                            style: {
                                                fontSize: '14px', 
                                                height: '32px' 
                                            }
                                        }}
                                        sx={{mt: '6px'}}
                                        name={key}
                                        value={formValues[key] || ''}
                                        onChange={(e) => handleChange(key, e.target.value)}
                                        fullWidth
                                        size="small"
                                    />
                                </>
                            )}
                        </Grid>
                    ))}
                </Grid>
            </DialogContent>
            <DialogActions
                sx={{ mb: '20px', justifyContent: 'center' }}
            >
                <Button
                    variant='contained'
                    sx={{ fontSize: '13px', textTransform: 'none' }}
                    endIcon={<Cancel />}
                    onClick={onClickCancel}
                >
                    Cancelar
                </Button>
                <Button
                    variant='contained'
                    sx={{ ml: '20px', fontSize: '13px', textTransform: 'none' }}
                    endIcon={<CheckCircle />}
                    onClick={onClickConfirm}
                >
                    Confirmar
                </Button>
            </DialogActions>
        </Dialog >
    );
}
