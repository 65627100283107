import React from 'react';
import { Box, Typography } from '@mui/material';

const InLineError = ({errorMessage, height, width}) => (
    <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={height}
        width={width}
    >
        <Typography>{errorMessage}</Typography>
    </Box>
);

export default InLineError;