import { createContext, useState } from 'react'

// Este es el que tenemos que consumir
export const ProcessesContext = createContext()

// Este es el que nos provee de acceso al contexto
export function ProcessesProvider ({ children }) {
    const [processesList, setProcessesList] = useState([])
    const [paginationData, setPaginationData] = useState({start: 0, limit: 10, count: 0, page: 1})
    const [appliedFilters, setAppliedFilters] = useState({})

  return (
    <ProcessesContext.Provider value={{
        processesList,
        setProcessesList,
        paginationData,
        setPaginationData,
        appliedFilters,
        setAppliedFilters
    }}
    >
      {children}
    </ProcessesContext.Provider>
  )
}