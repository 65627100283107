import { React } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
import { BLUE, DARK_GREY, GREY } from '../../../theme';
import { useResponsive } from '../../../hooks/useResponsive';



const data = [
    [19817464, '-', '-', '-', '-', '-', 3023, '-', '-', 584911, '-', 213732, '-', '-', '-', 20619131, 6269420],
    [1776890, '-', '-', '-', 17111, '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', 1794001, 1794001],
    [63595, '-', '-', '-', 65602, '-', 533243, '-', '-', '-', '-', '-', '-', '-', '-', 662440, 647380],
    [164855, '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', 164855, '-'],
    ['-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-'],
    ['-', 760514, '-', '-', 630978, '-', 169086, '-', '-', 103299, '-', '-', '-', '-', '-', 1663877, 618601],
    ['-', '-', '-', '-', 3023, '-', 563974, '-', '-', 9396045, 70633, '-', '-', '-', '-', 10033674, 8915092],
    ['-', '-', '-', '-', '-', '-', '-', '-', 5311352, '-', '-', '-', '-', '-', '-', 5311352, 5311352],
    ['-', '-', '-', '-', '-', 12870367, 2999548, '-', '-', '-', '-', '-', '-', '-', '-', 15869915, 15869915],
    ['-', '-', '-', '-', '-', '-', '-', '-', '-', 625618, 80507, '-', '-', '-', '-', 706125, 706125],
    ['-', '-', '-', '-', '-', '-', '-', '-', '-', '-', 282174, '-', '-', '-', '-', 282174, 282174],
    ['-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-'],
    ['-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-'],
    [805, '-', '-', '-', 5540, '-', 1884, '-', '-', 104267, 3901, '-', '-', 536, 7314, 124247, 124247],
    ['-', '-', '-', '-', '-', '-', '-', '-', '-', 43077, '-', 66113, '-', '-', '-', 109190, 109190],
    [1208450, '-', '-', '-', 61437, '-', '-', '-', '-', 1684908, '-', '-', '-', '-', '-', 2954795, 2954795],
];



const createData = (values) => (
    {
        id: values[0],
        category: values[1],
        riskPonderation1: values[2].toLocaleString("es-ES"),
        riskPonderation2: values[3].toLocaleString("es-ES"),
        riskPonderation3: values[4].toLocaleString("es-ES"),
        riskPonderation4: values[5].toLocaleString("es-ES"),
        riskPonderation5: values[6].toLocaleString("es-ES"),
        riskPonderation6: values[7].toLocaleString("es-ES"),
        riskPonderation7: values[8].toLocaleString("es-ES"),
        riskPonderation8: values[9].toLocaleString("es-ES"),
        riskPonderation9: values[10].toLocaleString("es-ES"),
        riskPonderation10: values[11].toLocaleString("es-ES"),
        riskPonderation11: values[12].toLocaleString("es-ES"),
        riskPonderation12: values[13].toLocaleString("es-ES"),
        riskPonderation13: values[14].toLocaleString("es-ES"),
        riskPonderation14: values[15].toLocaleString("es-ES"),
        riskPonderation15: values[16].toLocaleString("es-ES"),
        total: values[17].toLocaleString("es-ES"),
        unrated: values[18]
    }
);

const sumColumns = (data) => {
    if (!data.length) return [];
  
    // Initialize an array to store column sums with zeros
    const columnSums = new Array(data[0].length).fill(0);
  
    // Iterate through each row in the data
    for (const row of data) {
      // Iterate through each element in the row
      for (let i = 0; i < row.length; i++) {
        const cell = row[i];
        // Check if the cell is not '-' (meaning it's a number)
        if (cell !== '-') {
          // Add the value to the corresponding column sum
          columnSums[i] += parseInt(cell, 10);
        }
      }
    }
  
    // Check if all column sums are zero; if so, set them to '-'
    const result = columnSums.map((sum) => (sum === 0 ? '-' : sum));
  
    return result;
};


const createRows = (categories) => {
    return [
        createData([0, categories[0], ...data[0]]),
        createData([1, categories[1], ...data[1]]),
        createData([2, categories[2], ...data[2]]),
        createData([3, categories[3], ...data[3]]),
        createData([4, categories[4], ...data[4]]),
        createData([5, categories[5], ...data[5]]),
        createData([6, categories[6], ...data[6]]),
        createData([7, categories[7], ...data[7]]),
        createData([8, categories[8], ...data[8]]),
        createData([9, categories[9], ...data[9]]),
        createData([10, categories[10], ...data[10]]),
        createData([11, categories[11], ...data[11]]),
        createData([12, categories[12], ...data[12]]),
        createData([13, categories[13], ...data[13]]),
        createData([14, categories[14], ...data[14]]),
        createData([15, categories[15], ...data[15]]),
        createData([16, 'Total', ...sumColumns(data)]),
]};


const ponderationSubheaderNames = [
    '0%',
    '2%',
    '4%',
    '10%',
    '20%',
    '35%',
    '50%',
    '70%',
    '75%',
    '100%',
    '150%',
    '250%',
    '370%',
    '1250%',
    'Otras'
]

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    '&.MuiTableCell-root': {
        fontSize: '13px',
        padding: 0,
    },
    '&.MuiTableCell-head': {
        fontWeight: 700,
        borderBottom: 'solid 1px' + BLUE,
    },
    '&.MuiTableCell-body': {
        padding: '4px',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&.MuiTableRow-body': {
        border: 'solid 2px' + DARK_GREY
    },
    '&:last-child td': {
        fontWeight: 700,
        backgroundColor: GREY,
    },
}));





export default function RiskPonderationEADsTable(props) {
    const { categories } = props;
    const { isBelowXlScreen } = useResponsive();
    let rows = createRows(categories);


    return (
        <TableContainer 
            component={Paper}
            sx={{ overflowX: isBelowXlScreen ? 'auto' : 'hidden'}}
        >
            <Table sx={{ minWidth: '1400px' }}>
                <TableHead>
                    <StyledTableRow>
                        <StyledTableCell rowSpan={2} align='center' width={"20%"}>
                            Categorías de exposición
                        </StyledTableCell >
                        <StyledTableCell colSpan={15} align='center' width={"70%"}>
                            Ponderación de riesgo
                        </StyledTableCell>
                        <StyledTableCell rowSpan={2} align='center' width={"5%"}>
                            Total
                        </StyledTableCell>
                        <StyledTableCell rowSpan={2} align='center' width={"5%"}>
                            Del cual sin calificar
                        </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        {ponderationSubheaderNames.map((ponderationSubheaderName, index) => (
                            <StyledTableCell
                                key={ponderationSubheaderName}
                                align='center'
                                width={"4.66%"}
                            >
                                {ponderationSubheaderName}
                            </StyledTableCell>
                        ))}
                    </StyledTableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <StyledTableRow key={row.id}>
                            <StyledTableCell
                            >
                                {row.category}
                            </StyledTableCell>
                            <StyledTableCell
                                align='center'
                            >
                                {row.riskPonderation1}
                            </StyledTableCell>
                            <StyledTableCell
                                align='center'
                            >
                                {row.riskPonderation2}
                            </StyledTableCell>
                            <StyledTableCell
                                align='center'
                            >
                                {row.riskPonderation3}
                            </StyledTableCell>
                            <StyledTableCell
                                align='center'
                            >
                                {row.riskPonderation4}
                            </StyledTableCell>
                            <StyledTableCell
                                align='center'
                            >
                                {row.riskPonderation5}
                            </StyledTableCell>
                            <StyledTableCell
                                align='center'
                            >
                                {row.riskPonderation6}
                            </StyledTableCell>
                            <StyledTableCell
                                align='center'
                            >
                                {row.riskPonderation7}
                            </StyledTableCell>
                            <StyledTableCell
                                align='center'
                            >
                                {row.riskPonderation8}
                            </StyledTableCell>
                            <StyledTableCell
                                align='center'
                            >
                                {row.riskPonderation9}
                            </StyledTableCell>
                            <StyledTableCell
                                align='center'
                            >
                                {row.riskPonderation10}
                            </StyledTableCell>
                            <StyledTableCell
                                align='center'
                            >
                                {row.riskPonderation11}
                            </StyledTableCell>
                            <StyledTableCell
                                align='center'
                            >
                                {row.riskPonderation12}
                            </StyledTableCell>
                            <StyledTableCell
                                align='center'
                            >
                                {row.riskPonderation13}
                            </StyledTableCell>
                            <StyledTableCell
                                align='center'
                            >
                                {row.riskPonderation14}
                            </StyledTableCell>
                            <StyledTableCell
                                align='center'
                            >
                                {row.riskPonderation15}
                            </StyledTableCell>
                            <StyledTableCell
                                align='center'
                            >
                                {row.total}
                            </StyledTableCell>
                            <StyledTableCell
                                align='center'
                            >
                                {row.unrated}
                            </StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}