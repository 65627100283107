import { React } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Collapse, IconButton, Stack, Typography } from '@mui/material'
import { FilterAlt, PlayCircle, Replay, CleaningServices } from '@mui/icons-material'
import { StyledButton } from '../../components/CustomStyles'
import ProcessesTable from './ProcessesTable'
import { BLUE, CONTENT_BOX, SUBTITLE_TEXT, TITLE_TEXT } from '../../theme'
import { NEW_EXECUTION_PATH } from '../../config/Paths'
import { useProcessFilters } from '../../hooks/useProcessFilters'
import { useProcesses } from '../../hooks/useProcesses'
import Filters from './Filters'



export default function Processes() {
    const navigate = useNavigate()
    const {filters, fullDates, openFilters, handleOpenFilters,
        handleClearFilters, areAnyFilters, handleOnChangeFilter} = useProcessFilters()
    const { filterProcesses, loadProcesses } = useProcesses()
    


    return (
        <>
            <Box
                display={'flex'}
                justifyContent={'space-between'}
                sx={CONTENT_BOX}
            >
                <Typography
                    sx={TITLE_TEXT}
                    color={BLUE}
                >
                    Procesos
                </Typography>
                <StyledButton
                    onClick={() => navigate(NEW_EXECUTION_PATH)}
                    variant='contained'
                    startIcon={<PlayCircle />}
                >
                    Nueva ejecución
                </StyledButton>
            </Box>
            <Box sx={CONTENT_BOX}>
                <Stack
                    direction="row"
                    justifyContent={'space-between'}
                >
                    <Typography
                        sx={SUBTITLE_TEXT}
                        color={BLUE}
                    >
                        Ejecuciones
                    </Typography>
                    <Box>
                        {areAnyFilters() &&
                            <IconButton onClick={handleClearFilters}>
                                <CleaningServices />
                            </IconButton>
                        }
                        <IconButton onClick={loadProcesses}>
                            <Replay />
                        </IconButton>
                        <IconButton onClick={handleOpenFilters}>
                            <FilterAlt />
                        </IconButton>
                    </Box>
                </Stack>
                <Collapse
                    in={openFilters}
                    sx={{ mt: 1 }}
                >
                    <Filters 
                        filters={filters} 
                        fullDates={fullDates} 
                        handleOnChangeFilter={handleOnChangeFilter} 
                        filterProcesses={filterProcesses} 
                    />
                </Collapse>
                <ProcessesTable />
            </Box>
        </>
    )
}