import { React } from 'react';
import { IconButton } from '@mui/material';
import { AddOutlined, RemoveOutlined } from '@mui/icons-material';


export default function ExpandIcon(props) {
    const { size, open, onClick } = props;

    return (

        <IconButton
            size={size}
            onClick={onClick}
            sx={{ pointerEvents: 'auto' }}
        >
            {open ? <RemoveOutlined fontSize='small' /> : <AddOutlined fontSize='small' />}
        </IconButton>
    );
}