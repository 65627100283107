import { useEffect, useState } from "react";
import { entitiesService } from "../services/entitiesService";


export function useEntitiesConfig() {
    const [entities, setEntities] = useState([]);
    const [parameters, setParameters] = useState({});
    const { getEntities, putEntities } = entitiesService();


    useEffect(() => {
        fetchEntities();
    }, []);

    const fetchEntities = async () => {
        const data = await getEntities();
        if (data) {
            setEntities(data.current_values);
            setParameters(data.parameters);
        }
    };

    const updateEntities = async (updatedEntities) => {
        updatedEntities.sort((a, b) => parseInt(a.id_entidad_contable) - parseInt(b.id_entidad_contable));
        const data = await putEntities(updatedEntities);
        if (data) {
            setEntities(updatedEntities);
        }
    };

    return { entities, parameters, fetchEntities, updateEntities };
}
