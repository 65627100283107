import { React } from 'react';
import { Box, Typography } from '@mui/material';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { BLACK } from '../../../theme';
import { useResponsive } from '../../../hooks/useResponsive';


ChartJS.register(ArcElement, Tooltip, Legend);

const colors = [
    '#03045e',
    '#023e8a',
    '#0077b6',
    '#0096c7',
    '#00b4d8',
    '#48cae4',
    '#90e0ef',
    '#ade8f4',
    '#caf0f8',
    '#b7e4c7',
    '#95d5b2',
    '#74c69d',
    '#52b788',
    '#40916c',
    '#2d6a4f',
    '#1b4332',
    '#081c15'
]

const options = (isBelowMdScreen) => {
    return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                labels: {
                    color: BLACK,
                    boxWidth: 10,
                    font: {
                        size: 9
                    }
                },
                position: isBelowMdScreen ? 'bottom' : 'right',
            }
        }
    }
};

const data = (categories, chartData) => {
    return {
        labels: categories,
        datasets: [
            {
                label: 'Añadir tooltip',
                data: chartData,
                backgroundColor: colors,
                borderColor: colors,
                borderWidth: 1,
            },
        ],
    }
};

export default function CreditRiskPieChart(props) {
    const { header, categories, chartData } = props;
    const { isBelowMdScreen, isBelowXlScreen } = useResponsive();

    // Determine chart width based on screen size
    let chartWidth = '30vw'; // Default width
    if (isBelowXlScreen) chartWidth = '65vw';

    return (
        <Box sx={{ mt: '12px' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Typography sx={{ fontSize: '14px', fontWeight: 700 }} >
                    {header}
                </Typography>
            </Box>
            <Box sx={{ position: 'relative', height: isBelowMdScreen ? '370px' : '250px', width: chartWidth }}>
                <Pie
                    data={data(categories, chartData)}
                    options={options(isBelowMdScreen)}
                />
            </Box>
        </Box>
    );
}