import { React } from 'react';
import { Box } from '@mui/material';
import { CategoryScale, Chart as ChartJS, Filler, Legend, LineElement,
    LinearScale, PointElement, Title, Tooltip } from 'chart.js';
import { Line } from 'react-chartjs-2';


ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

const line = (timeDimensions, totals) => {
    return {
        labels: timeDimensions,
        datasets: [
            {
                label: 'Total',
                data: totals,
                borderColor: 'rgb(116, 198, 157)',
                backgroundColor: 'rgba(116, 198, 157, 0.5)',
                fill: 'origin'
            }
        ],
    }
};

const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false,
        }
    },
    scales: {
        x: {
            grid: {
                display: false
            },
            offset: true
        },
        y: {
            title: {
                display: true,
                text: 'Valor'
            },
            min: 0,
            max: 40000,
            ticks: {
                stepSize: 5000,
            },
        },
    },
};

export default function LineChart(props) {
    const {timeDimensions, totals} = props;

    return (
        <Box sx={{ position: 'relative', height: '25vh', width: '95vw' }}>
            <Line data={line(timeDimensions, totals)} options={options} />
        </Box>
    );
}