import { React } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
import { BLUE, DARK_GREY, GREY } from '../../../theme';
import { useResponsive } from '../../../hooks/useResponsive';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    '&.MuiTableCell-root': {
        fontSize: '13px',
        padding: 0,
    },
    '&.MuiTableCell-head': {
        fontWeight: 700,
        borderBottom: 'solid 1px' + BLUE,
    },
    '&.MuiTableCell-body': {
        padding: '4px',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&.MuiTableRow-body': {
        border: 'solid 2px' + DARK_GREY
    },
    '&:last-child td': {
        fontWeight: 700,
        backgroundColor: GREY,
    },
}));




export default function EvolutionTable(props) {
    const { timeDimensions, rows } = props;
    const { isBelowXlScreen } = useResponsive();

    return (
        <TableContainer 
            component={Paper}
            sx={{ overflowX: isBelowXlScreen ? 'auto' : 'hidden'}}
        >
            <Table sx={{ minWidth: '1400px' }}>
                <TableHead>
                    <StyledTableRow>
                        <StyledTableCell />
                        {timeDimensions.map(timeDimension => (
                            <StyledTableCell
                                key={timeDimension}
                                align='center'
                            >
                                {timeDimension}
                            </StyledTableCell   >
                        ))}
                    </StyledTableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <StyledTableRow key={row.name}>
                            <StyledTableCell>{row.name}</StyledTableCell>
                            {row.indexedValues.map((entry) => (
                                <StyledTableCell
                                    key={entry.id}
                                    align='center'
                                >
                                    {entry.value}
                                </StyledTableCell>
                            ))}
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}