import { createTheme } from '@mui/material';

export const BLUE = '#145DA0';
export const LIGHT_BLUE = '#ADD8E6';
export const BLACK = '#212547';
export const GREY = '#F2F2F2';
export const DARK_GREY = '#6F6F6F';
export const WHITE = '#FFFFFF';

export const CONTENT_BOX = {
    margin: '16px',
    backgroundColor: 'white',
    pl: '50px',
    pr: '50px',
    pt: '10px',
    pb: '10px',
    minHeight: '57px'
}

export const TITLE_TEXT = {
    fontSize: '16px',
    fontWeight: 700,
    mt: '6px',
}

export const SUBTITLE_TEXT = {
    fontSize: '16px',
    fontWeight: 400,
    mt: '8px',
}

export const BUTTON = {
    fontSize: '13px',
    textTransform: 'none',
}

export const SELECT_TEXT = {
    fontSize: '12px',
    fontWeight: 600
}

export const theme = createTheme({
    breakpoints: {
        values: {
            xs: 400,   // teléfonos móviles - pequeños dispositivos
            sm: 650, // teléfonos móviles - dispositivos medianos
            md: 900, // tabletas - dispositivos grandes
            lg: 1050, // laptops/desktops - dispositivos muy grandes
            xl: 1400, // monitores grandes - dispositivos extra grandes
        },
    },
    palette: {
        primary: {
            main: BLUE
        },
        secondary: {
            main: BLACK
        },
        info: {
            main: DARK_GREY
        }
    },
    components: {
        MuiGrid: {
            styleOverrides: {
                root: {
                    backgroundColor: 'white'
                }
            }
        },
        MuiTableContainer: {
            styleOverrides: {
                root: {
                    boxShadow: 'none'
                }
            }

        },
        MuiStepLabel: {
            styleOverrides: {
                label: {
                    "&.Mui-active": {
                        fontSize: '16px',
                        fontWeight: 400,
                        color: BLUE,
                    },
                    "&.Mui-disabled": {
                        fontSize: '16px',
                        fontWeight: 400,
                        color: 'grey',
                    },
                    "&.Mui-completed": {
                        fontSize: '16px',
                        fontWeight: 400,
                        color: BLUE,
                    }
                }
            },

        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: "2em",
                    color: "black",
                    backgroundColor: '#e8f0f8',
                    border: 'solid 1px black'
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    color: BLUE
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    fontSize: '16px',
                    fontWeight: 400,
                    mt: '8px',
                    padding: 0,
                    paddingRight: '30px'
                }
            }
        }
    }
});
