import { React, useState, useEffect } from 'react';
import { Box, Grid, Typography, IconButton, Collapse } from '@mui/material';
import { Settings as SettingsIcon } from '@mui/icons-material';
import { BLUE, SUBTITLE_TEXT, } from '../../theme';
import CustomSelect from '../../components/CustomSelect';
import EvolutionTable from './tables/EvolutionTable';
import LineChart from './graphs/LineChart';
import CreditRiskPieChart from './graphs/CreditRiskPieChart';
import CreditRiskBarChart from './graphs/CreditRiskBarChart';
import { useResponsive } from '../../hooks/useResponsive';
import faker from 'faker';
import BarLineChart from './charts/BarLineChart';
import HalfDonutChart from './charts/HalfDonutChart';


const growthList = [
    'Var. Mensual %',
    'Var. Trimestral %',
    'Var. Anual %'
];

const months = [
    '10/2022',
    '11/2022',
    '12/2022',
    '01/2023',
    '02/2023',
    '03/2023',
    '04/2023',
    '05/2023',
    '06/2023',
    '07/2023',
    '08/2023',
    '09/2023',
    '10/2023',
]

const terms = [
    '2021Q4',
    '2022Q1',
    '2022Q2',
    '2022Q3',
    '2022Q4',
    '2023Q1',
    '2023Q2',
    '2023Q3',
    '2023Q4',
]

const years = [
    '2019',
    '2020',
    '2021',
    '2022',
    '2023',
]

const indicators = [
    'Exposición',
    'APRs',
    'Ponderación media'
]


const SettingsMenu = (props) => {
    const { indicators, indicator, handleOnChangeIndicator, categories, selectedCategories, handleOnChangeCategories, growthList, dimension, handleOnChangeDimension } = props
    return (
        <>
            <Grid
                item
                xs={12}
                sm={12}
                md={3}
                lg={3}
                xl={2}
            >
                <CustomSelect
                    title={'Indicador'}
                    selectItems={indicators}
                    value={indicator}
                    onChange={handleOnChangeIndicator}
                    horizontal
                />
            </Grid>
            <Grid
                item
                xs={12}
                sm={12}
                md={3}
                lg={3}
                xl={2}
            >
                <CustomSelect
                    title={'Categorías'}
                    selectItems={categories}
                    value={selectedCategories}
                    onChange={handleOnChangeCategories}
                    horizontal
                    multiple
                />
            </Grid>
            <Grid
                item
                xs={12}
                sm={12}
                md={3}
                lg={3}
                xl={2}
            >
                <CustomSelect
                    title={'Medida'}
                    selectItems={growthList}
                    value={dimension}
                    onChange={handleOnChangeDimension}
                    horizontal
                />
            </Grid>
        </>
    );
}


const createData = (name, values) => {
    // Aplica el formateo al crear cada fila
    let indexedValues = values.map((value, index) => ({ 
        id: index, 
        value: value.toLocaleString("es-ES") 
    }));
    return { name, indexedValues };
}

const getRows = (categories, timeDimensions) => (
    categories.map(category => {
        let values = timeDimensions.map(() => faker.datatype.number({ min: 0.00, max: 3000 }));
        let row = createData(category, values);
        return row;
    })
);



export default function TabCreditRisks(props) {
    const { selectedCategories, categories, shortCategories, filteredCategories, handleOnChangeCategories, ponderacionMediaData, exposicionData, APRsData } = props;
    
    const { isBelowSmScreen, isBelowMdScreen, isBelowXlScreen } = useResponsive();
    const [selectedPieChart, setSelectedPieChart] = useState('Exposición');
    const [indicator, setIndicator] = useState('');
    const [dimension, setDimension] = useState('');
    const [timeDimensions, setTimeDimensions] = useState(months);
    const [expandedSettings, setExpandedSettings] = useState(false);

    let rows = getRows(filteredCategories, timeDimensions);

    useEffect(() => {
        // Check if the screen is below xl and expandedSettings is true
        if (!isBelowMdScreen && expandedSettings) {
            // Set expandedSettings to false
            setExpandedSettings(false);
        }
    }, [isBelowMdScreen]);

    const sums = timeDimensions.map((timeDimension, index) => (
        rows.reduce((acc, row) => 
            acc + parseFloat(row.indexedValues[index].value.replace(/\./g, '')), 0
        ).toLocaleString("es-ES")
    ));

    const sumRow = createData('Total', sums);

    rows.push(sumRow);
    
    const numericSums = sums.map(formattedSum => parseFloat(formattedSum.replace(/\./g, '')));

    const handleOnChangePieChart = (event) => {
        setSelectedPieChart(event.target.value);
    };

    const handleOnChangeIndicator = (event) => {
        setIndicator(event.target.value);
    };

    const handleOnChangeDimension = (event) => {
        setDimension(event.target.value);
        if (event.target.value === 'Var. Trimestral %') {
            setTimeDimensions(terms)
        }
        else if (event.target.value === 'Var. Anual %') {
            setTimeDimensions(years)
        }
        else {
            setTimeDimensions(months)
        }
    };

    const handleExpandSettings = () => {
        setExpandedSettings(!expandedSettings);
    };

    const BarPieChartBoxHeight = () => {
        if (isBelowXlScreen) {
            return isBelowMdScreen ? '700px' : '600px';
        }
        return '300px';
    };

    return (
        <>
            <Box sx={{ width: '100%'}}>
                <Box sx={{ width: '100%', mt: '20px', mr: '15px' }}>
                        <Typography
                            color={BLUE}
                            sx={SUBTITLE_TEXT}
                        >
                            Exposición, APRs y Ponderación Media por Categoría
                        </Typography>
                    <BarLineChart categories={shortCategories} ponderacionMediaData={ponderacionMediaData} exposicionData={exposicionData} APRsData={APRsData} />
                </Box>
                <Box sx={{ width: '100%', ml: '0px', display: 'flex' }}>
                    <Box sx={{ width: '33.33%', mt: '20px', mr: '15px', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center'}}>
                    <Typography
                            color={BLUE}
                            sx={SUBTITLE_TEXT}
                        >
                            Exposición por Categoría
                        </Typography>
                        <HalfDonutChart
                            seriesData={exposicionData}
                            labels={shortCategories}
                        />
                    </Box>
                    <Box sx={{ width: '33.33%', mt: '20px', mr: '15px', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center'}}>
                    <Typography
                            color={BLUE}
                            sx={SUBTITLE_TEXT}
                        >
                            APRs por Categoría
                        </Typography>
                        <HalfDonutChart 
                            seriesData={APRsData}
                            labels={shortCategories}
                        />
                    </Box>
                    <Box sx={{ width: '33.33%', mt: '20px', mr: '15px', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center'}}>
                    <Typography
                            color={BLUE}
                            sx={SUBTITLE_TEXT}
                        >
                            Ponderación Media por Categoría
                        </Typography>
                        
                        <HalfDonutChart 
                            seriesData={ponderacionMediaData}
                            labels={shortCategories}
                        />
                    </Box>
                </Box>
            </Box>

            <Grid
                container
                spacing={2}
                sx={{ mt: '30px' }}
            >
                <Grid
                    item
                    xs={11}
                    sm={11}
                    md={3}
                    lg={3}
                    xl={6}
                >
                    <Typography
                            color={BLUE}
                            sx={SUBTITLE_TEXT}
                        >
                            Evolución de indicadores
                        </Typography>
                </Grid>
                {isBelowMdScreen ? (
                    <Grid
                        item
                        xs={1}
                        sm={1}
                        sx={{ pl: '26px' }}
                    >
                        <IconButton sx={{color: BLUE}} onClick={handleExpandSettings}>
                            <SettingsIcon />
                        </IconButton>
                    </Grid>
                )
                :
                (
                    <SettingsMenu
                        indicators={indicators}
                        indicator={indicator}
                        handleOnChangeIndicator={handleOnChangeIndicator}
                        categories={categories}
                        selectedCategories={selectedCategories}
                        handleOnChangeCategories={handleOnChangeCategories}
                        growthList={growthList}
                        dimension={dimension}
                        handleOnChangeDimension={handleOnChangeDimension}
                    />
                )}
            </Grid>
            {isBelowMdScreen && (
                <Collapse in={expandedSettings} timeout="auto" unmountOnExit>
                    <SettingsMenu
                        indicators={indicators}
                        indicator={indicator}
                        handleOnChangeIndicator={handleOnChangeIndicator}
                        categories={categories}
                        selectedCategories={selectedCategories}
                        handleOnChangeCategories={handleOnChangeCategories}
                        growthList={growthList}
                        dimension={dimension}
                        handleOnChangeDimension={handleOnChangeDimension}
                    />
                </Collapse>
            )}

            <Box sx={{ display: 'flex', justifyContent: 'center', pt: '20px' }}>
                <LineChart timeDimensions={timeDimensions} totals={numericSums} />
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center', pt: '30px', pb: '30px' }}>
                <EvolutionTable categories={filteredCategories} timeDimensions={timeDimensions} rows={rows} />
            </Box>
        </>
    );
}