import React, { useState, useEffect } from 'react';
import { Box, Grid, Tabs, Typography, IconButton, Menu, MenuItem, Collapse } from '@mui/material';
import { AntTab } from '../../components/CustomStyles';
import CustomSelect from '../../components/CustomSelect';
import CustomAutocomplete from '../../components/CustomAutocomplete';
import TabCreditRisks from './TabCreditRisks';
import TabResumen from './summary-tab/TabResumen';
import RiskPonderationEADsTable from './tables/RiskPonderationEADsTable';
import CapitalRatiosEvolutionGraph from './graphs/CapitalRatiosEvolutionGraph';
import LeverageRatioEvolutionGraph from './graphs/LeverageRatioEvolutionGraph';
import LiquidityRatioEvolutionGraph from './graphs/LiquidityRatioEvolutionGraph';
import { BLUE, BLACK, CONTENT_BOX, SUBTITLE_TEXT, TITLE_TEXT } from '../../theme';
import {Menu as MenuIcon, Settings as SettingsIcon} from '@mui/icons-material';
import { useResponsive } from '../../hooks/useResponsive';
import faker from 'faker';



const tabMenuOptions = [
    'Riesgo de Crédito y Contraparte',
    'Riesgo de Mercado',
    'Riesgo Operacional'
]

const dates = [
    '01/2023',
    '02/2023',
    '03/2023',
    '04/2023'
];

const entities = [
    'Grupo Consolidado',
    'Banco de Crédito Cooperativo',
    'Cajamar Caja Rural',
    'Caixa Rural Torrent',
    'Caixa Rural Vila-real',
    'Caixaltea',
    'Caixa Rural Burriana',
    'Caixa Rural Nules',
    'Caixacallosa',
    'Caixapetrer',
    'Caixalqueries',
    'Caixa Sant Vicent',
    'Caja Rural de Cheste',
    'Caixa Rural d\'Alginet',
    'Caja Rural de Villar',
    'Caixaturís',
    'Caixa Rural Vilavella',
    'Caixa Rural Almenara',
    'Caixa Rural Vilafamés',
    'Caixa Rural Xilxes',
];

const growthList = [
    'Var. Mensual %',
    'Var. Trimestral %',
    'Var. Anual %'
];

const categoriesSA = [
    'Administraciones Centrales/Bancos Centrales',
    'Administraciones Regionales/Locales',
    'Entes Sector Público',
    'Bancos Multilaterales Desarrollo',
    'Organizaciones internacionales',
    'Entidades',
    'Empresas',
    'Minoristas',
    'Hipotecas sobre bienes inmuebles',
    'Impago',
    'Riesgos especialmente elevados',
    'Bonos garantizados',
    'Posiciones de titulización',
    'Entidades/empresas con evaluación a corto plazo',
    'Organismos de inversión colectiva',
    'Renta variable',
    'Otros elementos',
];

const shortCategoriesSA = [
    'AACC/BBCC',
    'Admins Regionales/Locales',
    'Entes Sector Público',
    'BMDs',
    'Orgs internacionales',
    'Entidades',
    'Empresas',
    'Minoristas',
    'Hipotecas sobre inmuebles',
    'Impago',
    'Riesgos elevados',
    'Bonos garantizados',
    'Posiciones de titulización',
    'Corto plazo',
    'Participaciones/acciones OIC',
    'Renta variable',
    'Otros elementos',
];

const categoriesIRB = [
    'Administraciones Centrales/Bancos Centrales',
    'Entidades',
    'Empresas',
    'Minoristas',
    'Renta variable',
    'Posiciones de titulización',
    'Otros elementos'
];

const shortCategoriesIRB = [
    'AACC/BBCC',
    'Entidades',
    'Empresas',
    'Minoristas',
    'Renta variable',
    'Posiciones de titulización',
    'Otros activos '   
];

const MainSettingsMenu = (props) => {
    const { dates, execution, handleOnChangeExecution, entities, setSelectedValue, setInputValue, isLabelVisible } = props
    return (
        <>
            <Grid
                item
                sm={6}
                md={4}
                lg={3}
                xl={2}
                sx={{ pr: '24px' }}
            >
                <CustomSelect
                    title={'Ejecución'}
                    selectItems={dates}
                    value={execution}
                    onChange={handleOnChangeExecution}
                    horizontal
                />
            </Grid>
            <Grid
                item
                sm={6}
                md={4}
                lg={3}
                xl={2}
                sx={{ pl: '26px' }}
            >
                <CustomAutocomplete
                    title={'Entidad'}
                    autocompleteItems={entities}
                    setSelectedValue={setSelectedValue}
                    setInputValue={setInputValue}
                    isLabelVisible={isLabelVisible}
                />
            </Grid>
        </>
    );
}

export default function CDMExecutions() {
    const { isBelowMdScreen } = useResponsive();
    const [execution, setExecution] = useState('');
    const [categorySA, setCategorySA] = useState([]);
    const [categoryIRB, setCategoryIRB] = useState([]);
    const [tab, setTab] = useState(0);
    const [inputValue, setInputValue] = useState('');
    const [selectedValue, setSelectedValue] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [expandedMainSettings, setExpandedMainSettings] = useState(false);
    

    // Determine if the label should be visible
    const isLabelVisible = !inputValue && !selectedValue;
    
    const [ponderacionMediaDataSA, setPonderacionMediaDataSA] = useState(shortCategoriesSA.map(() => faker.datatype.number({ min: 0, max: 1000 })));
    const [exposicionDataSA, setExposicionDataSA] = useState(shortCategoriesSA.map(() => faker.datatype.number({ min: 0, max: 1000 })));
    const [APRsDataSA, setAPRsDataSA] = useState(shortCategoriesSA.map(() => faker.datatype.number({ min: 0, max: 1000 })));
    const [ponderacionMediaDataIRB, setPonderacionMediaDataIRB] = useState(shortCategoriesIRB.map(() => faker.datatype.number({ min: 0, max: 1000 })));
    const [exposicionDataIRB, setExposicionDataIRB] = useState(shortCategoriesIRB.map(() => faker.datatype.number({ min: 0, max: 1000 })));
    const [APRsDataIRB, setAPRsDataIRB] = useState(shortCategoriesIRB.map(() => faker.datatype.number({ min: 0, max: 1000 })));


    console.log(ponderacionMediaDataSA)
    console.log(exposicionDataSA)
    console.log(APRsDataSA)
    

    useEffect(() => {
        // Check if the screen is below xl and expandedSettings is true
        if (!isBelowMdScreen && expandedMainSettings) {
            // Set expandedSettings to false
            setExpandedMainSettings(false);
        }
    }, [isBelowMdScreen]);

    const handleOnChangeExecution = (event) => {
        setExecution(event.target.value);
    };

    const handleOnChangeCategorySA = (event) => {
        let value = getValue(event);
        setCategorySA(value);
    };

    const handleOnChangeCategoryIRB = (event) => {
        let value = getValue(event);
        setCategoryIRB(value);
    };

    const getValue = (event) => {
        let value = event.target.value
        return typeof value === 'string' ? value.split(',') : value
    }

    const handleChangeTab = (_event, value) => {
        // setTab(value);
        setTab(0);
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (index) => {
        setTab(index);
        handleMenuClose();
    };

    const handleExpandMainSettings = () => {
        setExpandedMainSettings(!expandedMainSettings);
    };




    return (
        <>
            <Box
                sx={CONTENT_BOX}
            >
                <Grid container>
                    <Grid
                        item
                        xs={11}
                        md={4}
                        lg={6}
                        xl={8}
                    >
                        <Typography
                            color={BLUE}
                            sx={TITLE_TEXT}
                        >
                            CdM Ejecuciones
                        </Typography>
                    </Grid>
                    {isBelowMdScreen ? (
                        <Grid
                            item
                            xs={1}
                            sx={{ pl: '26px' }}
                        >
                            <IconButton sx={{color: BLUE}} onClick={handleExpandMainSettings}>
                                <SettingsIcon />
                            </IconButton>
                        </Grid>
                    )
                    :
                    (
                        <MainSettingsMenu
                            dates={dates}
                            execution={execution}
                            handleOnChangeExecution={handleOnChangeExecution}
                            entities={entities}
                            setSelectedValue={setSelectedValue}
                            setInputValue={setInputValue}
                            isLabelVisible={isLabelVisible}
                        />
                    )}
                </Grid>
                <Collapse in={expandedMainSettings} timeout="auto" unmountOnExit>
                    <Grid container>
                        {isBelowMdScreen && (
                            <MainSettingsMenu
                                dates={dates}
                                execution={execution}
                                handleOnChangeExecution={handleOnChangeExecution}
                                entities={entities}
                                setSelectedValue={setSelectedValue}
                                setInputValue={setInputValue}
                                isLabelVisible={isLabelVisible}
                            />
                        )}
                    </Grid>
                </Collapse>
            </Box >

            {!isBelowMdScreen && (
                <Tabs 
                    sx={{ml: '66px', mb: '0px', minHeight: "38px", height: "38px"}}
                    value={tab}
                    onChange={handleChangeTab}
                >
                    <AntTab
                        tab={0}
                        label={tabMenuOptions[0]}
                    />
                    <AntTab
                        tab={1}
                        label={tabMenuOptions[1]}
                    />
                    <AntTab
                        tab={2}
                        label={tabMenuOptions[2]}
                    />
                </Tabs>
            )}
            <Box sx={{...CONTENT_BOX, mt: '0px'}}>
                {isBelowMdScreen && (
                    <Box display={'flex'}>
                        <Typography
                            sx={{...TITLE_TEXT, mt: '9px'}}
                            color={BLUE}
                        >
                            {tabMenuOptions[tab]}
                        </Typography>
                        <IconButton sx={{ml: '10px', color: BLUE}} onClick={handleMenuOpen}>
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                        >
                            <MenuItem 
                                onClick={() => handleMenuItemClick(0)}
                                sx={{ color: tab === 0 ? BLUE : BLACK }}
                            >
                                {tabMenuOptions[0]}
                            </MenuItem>
                            <MenuItem 
                                // onClick={() => handleMenuItemClick(1)}
                                sx={{ color: tab === 1 ? BLUE : BLACK }}
                            >
                                {tabMenuOptions[1]}
                            </MenuItem>
                            <MenuItem 
                                // onClick={() => handleMenuItemClick(2)}
                                sx={{ color: tab === 2 ? BLUE : BLACK }}
                            >
                                {tabMenuOptions[2]}
                            </MenuItem>
                        </Menu>
                    </Box>
                )}
                {tab === 0 && (
                    <Box>
                        <TabCreditRisks
                            selectedCategories={categorySA}
                            categories={['Todas', ...categoriesSA]}
                            shortCategories={shortCategoriesSA}
                            filteredCategories={categorySA.length === 0 || categorySA.includes('Todas') ? categoriesSA : categorySA}
                            handleOnChangeCategories={handleOnChangeCategorySA}
                            ponderacionMediaData={ponderacionMediaDataSA}
                            exposicionData={exposicionDataSA}
                            APRsData={APRsDataSA}
                        />
                        <Typography
                            color={BLUE}
                            sx={SUBTITLE_TEXT}
                        >
                            EADs y ponderaciones de riesgo de cada categoría regulatoria (riesgo de crédito estándar)
                        </Typography>
                        <Box sx={{ mt: '20px', width: '100%', height: '600px' }}>
                            <RiskPonderationEADsTable categories={categoriesSA}/>
                        </Box>
                    </Box>
                )} 
            </Box>

            {/* <Box sx={CONTENT_BOX}>
                <Typography
                    color={BLUE}
                    sx={SUBTITLE_TEXT}
                >
                    Evolución trimestral de ratios de capital
                </Typography>
                <Box sx={{ mt: '20px', width: '100%', height: '300px' }}>
                    <CapitalRatiosEvolutionGraph />
                </Box>
            </Box>
            <Box sx={CONTENT_BOX}>
                <Typography
                    color={BLUE}
                    sx={SUBTITLE_TEXT}
                >
                    Evolución trimestral del ratio de apalancamiento
                </Typography>
                <Box sx={{ mt: '20px', width: '100%', height: '300px' }}>
                    <LeverageRatioEvolutionGraph />
                </Box>
            </Box>
            <Box sx={CONTENT_BOX}>
                <Typography
                    color={BLUE}
                    sx={SUBTITLE_TEXT}
                >
                    Evolución trimestral de la ratio de cobertura de la liquidez
                </Typography>
                <Box sx={{ mt: '20px', width: '100%', height: '300px' }}>
                    <LiquidityRatioEvolutionGraph />
                </Box>
            </Box> */}
        </>
    );
}