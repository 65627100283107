import { React, useState, useEffect } from 'react';
import { Box, Typography, Tabs, Dialog, DialogActions, DialogContent, 
    DialogTitle, Button, IconButton, Menu, MenuItem, Collapse } from '@mui/material';
import { HelpOutlineOutlined, Cancel, Settings as SettingsIcon, FileDownloadOutlined, Menu as MenuIcon } from '@mui/icons-material';
import {AntTab, AntDisabledTab, AntDisabledTabs, StyledDatePicker} from '../../../components/CustomStyles';
import CustomSelect from '../../../components/CustomSelect';
import SummaryTable from './SummaryTable';
import SummaryByEntityTable from './SummaryByEntityTable';
import { BLUE, BLACK, CONTENT_BOX, TITLE_TEXT, SELECT_TEXT } from '../../../theme';
import { useResponsive } from '../../../hooks/useResponsive';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';


const EXCEL_FILE_PATH = '/Informe_Riesgo_Credito_16122022_121723_30112022.xlsx'

const menuOptions = [
    'Resumen',
    'Resumen por entidad',
    'Renta variable',
    'Renta fija',
    'Derivados',
    'Inversión crediticia',
    'Resto'
]

const normativeVersionsList = [
    'Reporting CRR2',
    'Reporting CRR3'
];

const executionsList = [
    'dcaab6cc-7b97-4b64-8506-fd2b2f962ba9'
];


const SettingsMenu = (props) => {
    const { executionDate, handleOnChangeExecutionDate, execution, handleOnChangeExecution, normativeVersion, handleOnChangeNormativeVersion, isBelowXlScreen, isBelowMdScreen } = props
    return (
        <Box 
            display={'flex'}
            flexDirection={isBelowMdScreen ? 'column' : 'row'}
            justifyContent={'space-evenly'}
        >
            <Button
                variant='contained'
                endIcon={<FileDownloadOutlined />}
                sx={{ mr: isBelowXlScreen ? null : '24px', fontSize: '13px', textTransform: 'none' }}
                href={EXCEL_FILE_PATH}
                download={true}
            >
                Exportar Excel
            </Button>
            <Box
                display={'flex'}
                sx={{ pr: isBelowXlScreen ? null : '26px' }}
            >
                <Typography sx={{ ...SELECT_TEXT, mt: '10px', mr: '12px' }}>
                    Fecha de ejecución
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <StyledDatePicker
                        sx={{ width: '140px' }}
                        views={['year', 'month']} 
                        value={executionDate} 
                        onChange={(value) => handleOnChangeExecutionDate(value)}
                        slotProps={{
                            actionBar: {
                                actions: ['clear']
                            }
                        }}
                        closeOnSelect={true}
                    />
                </LocalizationProvider>
            </Box>
            <Box
                display={'flex'}
            >
                <Typography sx={{ ...SELECT_TEXT, mt: '10px', mr: '12px' }}>
                    Versión normativa
                </Typography>
                <CustomSelect
                    selectItems={normativeVersionsList}
                    value={normativeVersion}
                    onChange={handleOnChangeNormativeVersion}
                    horizontal
                />
            </Box>
            <Box
                sx={{ pl: isBelowXlScreen ? null : '26px' }}
            >
                <CustomSelect
                    maxWidth={'150px'}
                    title={'Ejecución'}
                    selectItems={executionsList}
                    value={execution}
                    onChange={handleOnChangeExecution}
                    horizontal
                />
            </Box>
        </Box>
    );
}



export default function CreditRiskReport() {
    const { isBelowMdScreen, isBelowXlScreen } = useResponsive();
    const [executionDate, setExecutionDate] = useState(dayjs('2024-07-01'));
    const [normativeVersion, setNormativeVersion] = useState('Reporting CRR2');
    const [execution, setExecution] = useState('dcaab6cc-7b97-4b64-8506-fd2b2f962ba9');
    const [tab, setTab] = useState(0);
    const [openDialog, setOpenDialog] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [expandedSettings, setExpandedSettings] = useState(false);
    

    useEffect(() => {
        // Check if the screen is below xl and expandedSettings is true
        if (!isBelowXlScreen && expandedSettings) {
            // Set expandedSettings to false
            setExpandedSettings(false);
        }
    }, [isBelowXlScreen]);

    const handleClickOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleTabChange = (event, newTab) => {
        setTab(newTab);
    };

    const handleOnChangeExecutionDate = (event) => {
        setExecutionDate(event.target.value);
    };

    const handleOnChangeNormativeVersion = (event) => {
        setNormativeVersion(event.target.value);
    };

    const handleOnChangeExecution = (event) => {
        setExecution(event.target.value);
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (index) => {
        setTab(index);
        handleMenuClose();
    };

    const handleExpandSettings = () => {
        setExpandedSettings(!expandedSettings);
    };


    return (
        <>
            <Box
                sx={CONTENT_BOX}
            >
                <Box display={'flex'} justifyContent={'space-between'} >
                    <Box display={'flex'} >
                        <Typography
                            sx={{...TITLE_TEXT, mt: '9px'}}
                            color={BLUE}
                        >
                            Riesgo de Crédito y Contraparte
                        </Typography>
                        <IconButton sx={{ml: '10px', color: BLUE}} onClick={handleClickOpenDialog}>
                            <HelpOutlineOutlined />
                        </IconButton>
                        <Dialog
                            open={openDialog}
                            onClose={handleCloseDialog}
                            maxWidth='lg'
                            fullWidth
                        >
                            <DialogTitle
                                color={BLUE}
                                sx={TITLE_TEXT}
                            >
                                Riesgo de Crédito y Contraparte
                            </DialogTitle>
                            <DialogContent >
                                {"Fichero donde se incluye el detalle de Riesgo de Crédito tanto a nivel grupal como granular, en concreto:"}{' '}<br />
                                <span>&bull;    </span><u>{"Hoja Resumen:"}</u> {" Resultados agrupados por epígrafe, categoría y ponderación donde se puede consultar la exposición, cobertura y APRs obtenidos."}{' '}<br />
                                <span>&bull;    </span><u>{"Hoja Resumen entidad:"}</u> {" Resultados agrupados por entidad, epígrafe, categoría y ponderación donde se puede consultar la exposición, cobertura y APRs obtenidos."}{' '}<br />
                                <span>&bull;    </span><u>{"Hoja Renta variable, Renta fija, Derivados, Inversión crediticia, Resto:"}</u> {" Resultados desglosados a nivel de contrato y cuenta contable, donde además de exposición, cobertura y APRs se pueden consultar atributos a nivel de operación para obtener una justificación de la asignación de categoría y ponderación obtenida."}{' '}<br />
                                <span>&bull;    </span><u>{"Hoja Diferencias:"}</u> {" Contratos con categorías y/o ponderaciones distintas entre este mes y el anterior."}{' '}<br />
                            </DialogContent>
                            <DialogActions
                                sx={{ mb: '20px', justifyContent: 'center' }}
                            >
                                <Button
                                    variant='contained'
                                    sx={{ fontSize: '13px', textTransform: 'none' }}
                                    endIcon={<Cancel />}
                                    onClick={handleCloseDialog}
                                >
                                    Cerrar
                                </Button>
                            </DialogActions>
                        </Dialog >
                    </Box>
                    {!isBelowXlScreen ? (
                        <SettingsMenu 
                            executionDate={executionDate} 
                            handleOnChangeExecutionDate={handleOnChangeExecutionDate}
                            execution={execution}
                            handleOnChangeExecution={handleOnChangeExecution}
                            normativeVersion={normativeVersion}
                            handleOnChangeNormativeVersion={handleOnChangeNormativeVersion}
                        />
                    )
                    :
                    (
                        <IconButton sx={{color: BLUE}} onClick={handleExpandSettings}>
                            <SettingsIcon />
                        </IconButton>
                    )}
                </Box>
                {isBelowXlScreen && (
                    <Collapse in={expandedSettings} timeout="auto" unmountOnExit>
                        <SettingsMenu 
                            executionDate={executionDate} 
                            handleOnChangeExecutionDate={handleOnChangeExecutionDate}
                            execution={execution}
                            handleOnChangeExecution={handleOnChangeExecution}
                            isBelowXlScreen={isBelowXlScreen}
                            isBelowMdScreen={isBelowMdScreen}
                            normativeVersion={normativeVersion}
                            handleOnChangeNormativeVersion={handleOnChangeNormativeVersion}
                        />
                    </Collapse>
                )}
            </Box>
            {!isBelowXlScreen &&
            (
                <Box display={'flex'}>
                    <Tabs 
                        sx={{ml: '66px', mb: '0px', minHeight: "38px", height: "38px"}} 
                        value={tab} 
                        onChange={handleTabChange}
                    >
                        <AntTab 
                            tab={0}
                            label="Resumen" 
                        />
                        <AntTab 
                            tab={1}
                            label="Resumen por entidad" 
                        />
                    </Tabs>
                </Box>
            )}
                <Box sx={{...CONTENT_BOX, mt: '0px'}}>
                    {isBelowXlScreen && (
                        <Box display={'flex'}>
                            <Typography
                                    sx={{...TITLE_TEXT, mt: '9px'}}
                                    color={BLUE}
                                >
                                    {menuOptions[tab]}
                            </Typography>
                            <IconButton sx={{ml: '10px', color: BLUE}} onClick={handleMenuOpen}>
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                            >
                                <MenuItem 
                                    onClick={() => handleMenuItemClick(0)}
                                    sx={{ color: tab === 0 ? BLUE : BLACK }}
                                >
                                    Resumen
                                </MenuItem>
                                <MenuItem 
                                    onClick={() => handleMenuItemClick(1)}
                                    sx={{ color: tab === 1 ? BLUE : BLACK }}
                                >
                                    Resumen por entidad
                                </MenuItem>
                            </Menu>
                            
                        </Box>
                    )}
                    {tab === 0 && (
                        <SummaryTable />
                    )}
                    {tab === 1 && (
                        <SummaryByEntityTable />
                    )}
                </Box>
        </>
    );
}