import React from 'react';
import { Chart as ChartJS, LinearScale, CategoryScale, BarElement, PointElement, 
  LineElement, Legend, Tooltip, LineController, BarController } from 'chart.js';
import { Chart } from 'react-chartjs-2';
import faker from 'faker';


ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

const terms = [
  '2019 T1',
  '2019 T2',
  '2019 T3',
  '2019 T4',
  '2020 T1',
  '2020 T2',
  '2020 T3',
  '2020 T4',
  '2021 T1',
  '2021 T2',
  '2021 T3',
  '2021 T4',
  '2022 T1',
  '2022 T2',
  '2022 T3',
  '2022 T4',
]

const data = (liquidityCushion, netLiquidityOutflow, liquidityRatio) => {
  return {
    labels: terms,
    datasets: [
      {
          yAxisID: "right-y-axis",
          type: 'line',
          label: 'Ratio de cobertura de liquidez',
          borderColor: 'rgb(255, 0, 0)',
          borderWidth: 2,
          fill: false,
          data: liquidityRatio,
      },
      {
          yAxisID: "left-y-axis",
          type: 'bar',
          label: 'Colchón de liquidez',
          backgroundColor: 'rgb(53, 162, 235)',
          data: liquidityCushion,
          borderColor: 'white',
          borderWidth: 2,
      },
      {
          yAxisID: "left-y-axis",
          type: 'bar',
          label: 'Salida neta de liquidez',
          backgroundColor: 'rgb(75, 192, 192)',
          data: netLiquidityOutflow,
          borderColor: 'white',
          borderWidth: 2,
      },
    ],
  }
};

const options = {
  maintainAspectRatio: false,
  scales: {
    "left-y-axis": {
      type: "linear",
      position: "left",
      grid: {
        borderDash: [8, 6],
        lineWidth: 2
      },
      ticks: {
        maxTicksLimit: 6
      }
    },
    "right-y-axis": {
      type: "linear",
      position: "right",
      grid: {
        display: false
      },
      ticks: {
        callback: (v) => v + "%",
        maxTicksLimit: 6,
        beginAtZero: false
      }
    }
  }
};

export default function LiquidityRatioEvolutionGraph(props) {
  const liquidityCushion = terms.map(() => faker.datatype.number({ min: 500, max: 800 }))
  const netLiquidityOutflow = terms.map(() => faker.datatype.number({ min: 300, max: 400 }))
  const liquidityRatio = terms.map(() => faker.datatype.number({ min: 160, max: 220 }))
  
  return <Chart 
              type='bar' 
              data={data(liquidityCushion, netLiquidityOutflow, liquidityRatio)} 
              height="100%"
              width="100%"
              options={options}
          />;
}