import React from 'react';
import { Box, Typography } from '@mui/material';
import { Chart as ChartJS, LinearScale, CategoryScale, BarElement, PointElement, 
  LineElement, Legend, Tooltip, LineController, BarController } from 'chart.js';
import { Chart } from 'react-chartjs-2';
import faker from 'faker';
  

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);


const dates = [
  'Ene 2023',
  'Feb 2023',
  'Mar 2023',
  'Abr 2023',
  'May 2023',
  'Jun 2023',
  'Jul 2023',
  'Ago 2023',
  'Sep 2023',
  'Oct 2023',
  'Nov 2023',
  'Dic 2023'
];

const generateData = (phasedInData, fullyLoadedData, requiredData) => {
  return {
    labels: dates,
    datasets: [
      {
        type: 'line',
        label: 'Required',
        borderColor: 'rgb(255, 99, 132)',
        borderWidth: 2,
        fill: false,
        data: requiredData,
      },
      {
        type: 'bar',
        label: 'Phased in',
        backgroundColor: 'rgb(75, 192, 192)',
        data: phasedInData,
        borderColor: 'white',
        borderWidth: 2,
      },
      {
        type: 'bar',
        label: 'Fully loaded',
        backgroundColor: 'rgb(53, 162, 235)',
        data: fullyLoadedData,
      },
    ],
  }
};

const generateRatioData = (ratioData, requiredData) => {
  return {
    labels: dates,
    datasets: [
      {
        type: 'line',
        label: 'Required',
        borderColor: 'rgb(255, 99, 132)',
        borderWidth: 2,
        fill: false,
        data: requiredData,
      },
      {
        type: 'bar',
        label: 'Ratio',
        backgroundColor: 'rgb(75, 192, 192)',
        data: ratioData,
        borderColor: 'white',
        borderWidth: 2,
      },
    ],
  }
};



export function PrincipalRatiosBarChart(props) {
  const { header, phasedInRange, fullyLoadedRange, ratioRange, requiredData } = props;

  return (
      <Box sx={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <Typography sx={{ fontSize: '14px', fontWeight: 700 }} >
                  {header}
              </Typography>
          <Box sx={{ position: 'relative', height: '200px', width: '100%' }}>
              { ratioRange.length === 0 ?
                  <Chart 
                      type='bar' 
                      data={generateData(dates.map(() => parseFloat((faker.datatype.number({ min: phasedInRange[0], max: phasedInRange[1] }) + Math.random()).toFixed(2))),
                                  dates.map(() => parseFloat((faker.datatype.number({ min: fullyLoadedRange[0], max: fullyLoadedRange[1] }) + Math.random()).toFixed(2))), 
                                  requiredData)} 
                      height="100%"
                      width="100%"
                      options={{ maintainAspectRatio: false, plugins: {
                          legend: {
                            display: true,
                            position: "top",
                          }} 
                      }}
                  />
                  :
                  <Chart 
                      type='bar' 
                      data={generateRatioData(dates.map(() => parseFloat((faker.datatype.number({ min: ratioRange[0], max: ratioRange[1] }) + Math.random()).toFixed(2))), requiredData)} 
                      height="100%"
                      width="100%"
                      options={{ maintainAspectRatio: false, plugins: {
                          legend: {
                            display: true,
                            position: "top",
                          }} 
                      }}
                  />
              }
          </Box>
      </Box>
      
  );
}
