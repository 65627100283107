import React from 'react';
import { Chart as ChartJS, LinearScale, CategoryScale, BarElement, PointElement, 
  LineElement, Legend, Tooltip, LineController, BarController } from 'chart.js';
import { Chart } from 'react-chartjs-2';
  
ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

const data = (categories, ponderacionMediaData, exposicionData, APRsData) => {
  return {
    labels: categories,
    datasets: [
      {
        type: 'line',
        label: 'Ponderación Media',
        borderColor: 'rgb(255, 99, 132)',
        borderWidth: 2,
        fill: false,
        data: ponderacionMediaData,
      },
      {
        type: 'bar',
        label: 'Exposición',
        backgroundColor: 'rgb(75, 192, 192)',
        data: exposicionData,
        borderColor: 'white',
        borderWidth: 2,
      },
      {
        type: 'bar',
        label: 'APRs',
        backgroundColor: 'rgb(53, 162, 235)',
        data: APRsData,
      },
    ],
  }
};



export default function CreditRiskBarChart(props) {
  const { categories, ponderacionMediaData, exposicionData, APRsData } = props;
  
  return (
    <Chart 
        type='bar' 
        data={data(categories, ponderacionMediaData, exposicionData, APRsData)} 
        options={{ maintainAspectRatio: false }}
    />
  )
}
