import { React, useState, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import { Box, Collapse, Grid, Typography, Dialog, DialogActions, Menu, MenuItem, Divider, Stack,
         DialogContent, DialogTitle, Button, Tabs, Checkbox, IconButton, TextField } from '@mui/material';
import { AntTab } from '../../../components/CustomStyles';
import { FileUpload, ArrowCircleRight, Cancel, CheckCircle, CloudUpload, Menu as MenuIcon, HighlightOff } from '@mui/icons-material/';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { StyledDatePicker } from '../../../components/CustomStyles';
import { StyledButton, StyledBox } from '../../../components/CustomStyles';
import EntryFilesTable from '../tables/EntryFilesTable';
import CustomSelect from '../../../components/CustomSelect';
import { SELECT_TEXT, BLUE, BLACK, TITLE_TEXT, DARK_GREY, CONTENT_BOX, SUBTITLE_TEXT } from '../../../theme';
import { useResponsive } from '../../../hooks/useResponsive';
import { parametrizationService } from '../../../services/parametrizationService';
import { loadFilesService } from '../../../services/loadFilesService';



const changeMonthStructure = (month) => {
    var changedMonth = String(month + 1);
    if (changedMonth.length === 1) {
        changedMonth = '0' + changedMonth;
    }
    return changedMonth;
}


const UploadDialog = (props) => {
    const { openUploadDialog, toggleUploadDialog, fileType, fetchLoadData, initialDate } = props;
    const { postLoadFile } = loadFilesService();
    const [fileDate, setFileDate] = useState(initialDate);
    const [file, setFile] = useState(null);
    const [fileUploadDescription, setFileUploadDescription] = useState('');

    useEffect(() => {
        setFileDate(initialDate)
    }, [initialDate])

    const handleFileUploadDescriptionChange = (event) => {
        setFileUploadDescription(event.target.value);
    };

    const onDrop = (acceptedFiles) => {
        setFile(acceptedFiles[0]);
    };

    const removeFile = () => {
        URL.revokeObjectURL(file.preview); // Liberar memoria
        setFile(null); // Elimina el archivo del estado
    };

    const submitFileUpload = async () => {
        if (file) {
            const formData = new FormData();
            formData.append("uploaded_file", file);
            await postLoadFile({formData: formData, month: changeMonthStructure(fileDate.$M), year: fileDate.$y, dataSet: fileUploadDescription })
        }
        setFileDate(initialDate)
        removeFile();
        toggleUploadDialog();
        fetchLoadData()
    };

    const handleOnChangeFileDate = (value) => {
        setFileDate(value)
    };
    
    return (
        <Dialog
            open={openUploadDialog}
            onClose={toggleUploadDialog}
            maxWidth='lg'
            fullWidth
        >
            <DialogTitle
                color={BLUE}
                sx={TITLE_TEXT}
            >
                Carga de archivo de proceso.
            </DialogTitle>
            <DialogContent >
                <Box sx={{ display: 'flex', justifyContent: 'center', mb: '10px' }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                        <StyledDatePicker 
                            sx={{ width: '40%' }}
                            views={['year', 'month']} 
                            value={fileDate} 
                            onChange={(value) => handleOnChangeFileDate(value)}
                            slotProps={{
                                actionBar: {
                                    actions: ['clear']
                                }
                            }}
                            closeOnSelect={true}
                        />
                    </LocalizationProvider>
                </Box>
                <TextField
                    label="Descripción"
                    multiline
                    rows={2} // Adjust the number of rows as needed
                    variant="outlined"
                    value={fileUploadDescription}
                    onChange={handleFileUploadDescriptionChange}
                    fullWidth 
                    sx={{ mt: '4px' }}
                />
                {file ? 
                    <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                        <Typography >
                            Archivo seleccionado:
                        </Typography>
                        <Typography sx={{color: BLUE, mx: '6px'}} >
                            {file.name}
                        </Typography>
                        <Typography >
                            ({Math.round(file.size / 1024)} KB)
                        </Typography>
                        <IconButton onClick={removeFile}>
                            <HighlightOff style={{ color: DARK_GREY }}/>
                        </IconButton>
                    </Box>
                :
                <Dropzone onDrop={onDrop}>
                    {({ getRootProps, getInputProps }) => (
                        <StyledBox {...getRootProps()}>
                            <input {...getInputProps()} />
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Typography>Arrastre y suelte el fichero de carga, o haga clic para seleccionarlo</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <CloudUpload sx={{ fontSize: 100 }} />
                            </Box>
                        </StyledBox>
                    )}
                </Dropzone >
                }
            </DialogContent>
            <DialogActions
                sx={{ mb: '20px', justifyContent: 'center' }}
            >
                <Button
                    variant='contained'
                    sx={{ fontSize: '13px', textTransform: 'none' }}
                    endIcon={<Cancel />}
                    onClick={toggleUploadDialog}
                >
                    Cancelar
                </Button>
                <Button
                    variant='contained'
                    sx={{ ml: '20px', fontSize: '13px', textTransform: 'none' }}
                    endIcon={<CheckCircle />}
                    onClick={() => {submitFileUpload()}}
                    disabled={!(file && fileDate)}
                >
                    Confirmar
                </Button>
            </DialogActions>
        </Dialog >
    );
}



export default function StepOne(props) {
    const { activeStep, handleOnClickNext, selectFilters, setSelectFilters, checks, setChecks,
            selectedEntryFiles, setSelectedEntryFiles } = props;
    const { getParametrization } = parametrizationService();
    const { getLoadFiles } = loadFilesService();
    const { isBelowXlScreen } = useResponsive();
    const [processesNamesAndFileTypes, setProcessesNamesAndFileTypes] = useState([]);
    const [filteredFileTypes, setFilteredFileTypes] = useState([]);
    const [entryFiles, setEntryFiles] = useState([]);
    const [paginationData, setPaginationData] = useState({start: 0, limit: 5, count: 0, page: 1});
    const [ebaNormativeList, setEbaNormativeList] = useState([]);
    const [openUploadDialog, setOpenUploadDialog] = useState(false);
    const [selectedFileTypeTab, setSelectedFileTypeTab] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);

    // console.log("processesNamesAndFileTypes: ", processesNamesAndFileTypes)
    // console.log("selectFilters: ", selectFilters)
    // console.log("selectedEntryFiles: ", selectedEntryFiles)
    // console.log("entryFiles: ", entryFiles)
    // console.log("selectedFileTypeTab: ", selectedFileTypeTab)
    // console.log("filteredFileTypes: ", filteredFileTypes)    

    useEffect(() => {
        fetchParametrization();
    }, []);

    useEffect(() => {
        if (showEntryFilesTable()) {
            fetchLoadData()
        }
    }, [selectFilters, checks.lastLoadChecked, selectedFileTypeTab]);

    useEffect(() => {
        if (selectFilters.process) {
            setFilteredFileTypes(filterFileTypes());
        }
    }, [selectFilters.process, checks.mappingCNNSEpigraphChecked, checks.accountingChecked]);

    const fetchParametrization = async () => {
        const data = await getParametrization({screenId: 1})
        if (data) {
            setProcessesNamesAndFileTypes(data.processes);
            setEbaNormativeList(data.normative_version)
        }
    }

    const fetchLoadData = async () => {
        const fileType = filteredFileTypes[selectedFileTypeTab];
        const data = await getLoadFiles({
            data_type: fileType, 
            month: changeMonthStructure(selectFilters.fullDataDate.$M), 
            year: selectFilters.fullDataDate.$y, 
            limit: paginationData.limit, 
            start: paginationData.start
        })
        if (data && data.results.length > 0 && !selectedEntryFiles.hasOwnProperty('id'+fileType)){
            const initialEntryfile = data.results[0];
            const [year, month] = initialEntryfile.data_date.split("-");
            setSelectedEntryFiles({
                ...selectedEntryFiles, 
                ['id'+fileType]: {
                    month: month,
                    year: year,
                    fileID: initialEntryfile.file_id
                }
            })
        } 
        setEntryFiles(data.results);
        setPaginationData({start: data.start, limit: data.limit, count: data.count, page: 1});
    }

    const filterFileTypes = () => {
        return processesNamesAndFileTypes.find(process => process.name === selectFilters.process).fileTypes.filter(fileType => {
            if (fileType === 'MapeoCCNSEpigrafe' && !checks.mappingCNNSEpigraphChecked) {
                return false;
            }
            if (fileType === 'Contabilidad' && !checks.accountingChecked) {
                return false;
            }
            return true;
        });
    }
    

    const handleOnChangeProcess = (event) => {
        setSelectFilters({...selectFilters, process: event.target.value});
        setSelectedEntryFiles({});
    };

    const handleOnChangeEBANormative = (event) => {
        setSelectFilters({...selectFilters, ebaNormative: event.target.value});
        setSelectedEntryFiles({});
    };

    const handleOnChangeDataDate = (value) => {
        setSelectFilters({...selectFilters, fullDataDate: value})
        setSelectedEntryFiles({});
    };

    const handleOnClickRow = (row) => {
        const [year, month] = row.data_date.split("-");
        setSelectedEntryFiles({
            ...selectedEntryFiles,
            ['id' + row.data_type]: {
                month: month, 
                year: year,
                fileID: row.file_id
            }
        })
    }

    const isStepOneNotDone = () => {
        return selectFilters.process.length < 1
        || selectFilters.ebaNormative.length < 1
        || !selectFilters.fullDataDate
        || (Object.keys(selectedEntryFiles).length === 0
        && !checks.lastLoadChecked);
    };

    const showEntryFilesTable = () => {
        return selectFilters.process.length > 0 
            && selectFilters.ebaNormative.length > 0 
            && selectFilters.fullDataDate 
            && !checks.lastLoadChecked;
    }

    const toggleUploadDialog = () => {
        setOpenUploadDialog(!openUploadDialog);
    };

    const handleChangeTab = (_event, value) => {
        setSelectedFileTypeTab(value);
        setEntryFiles([]);
    };

    const handleChangeChecked = (event) => {
        const checkName = event.target.name;
        if (checkName === "lastLoadCheck") {
            setSelectedEntryFiles({})
            setChecks({...checks, lastLoadChecked: event.target.checked});
        }
        else if  (checkName === "accountingCheck") {
            setChecks({...checks, accountingChecked: event.target.checked});
        }
        else if  (checkName === "mappingCNNSEpigraphCheck") {
            setChecks({...checks, mappingCNNSEpigraphChecked: event.target.checked});
        }
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (index) => {
        setSelectedFileTypeTab(index);
        setEntryFiles([]);
        handleMenuClose();
    };


    return (
        <Collapse in={activeStep === 0}>
            <Box sx={{...CONTENT_BOX, mt: '0px', pb: '25px' }}>
            <Typography
                    color={BLUE}
                    sx={SUBTITLE_TEXT}
            >
                Selección del tipo de proceso
            </Typography>
                <Grid
                    container
                    spacing={2}
                    sx={{ mt: '0px', mb: '15px' }}
                >
                    <Grid
                        item
                        xs={12}
                        sm={4}
                    >
                        <CustomSelect
                            title={'Proceso'}
                            selectItems={processesNamesAndFileTypes?.map(process => process.name)}
                            value={selectFilters.process}
                            onChange={handleOnChangeProcess}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={4}
                    >
                        <CustomSelect
                            title={'Versión EBA y Normativa'}
                            selectItems={ebaNormativeList}
                            value={selectFilters.ebaNormative}
                            onChange={handleOnChangeEBANormative}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={4}
                    >
                        <Typography sx={{ ...SELECT_TEXT, mb: '5px' }}>
                            Fecha de datos
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <StyledDatePicker 
                                sx={{ width: '100%' }}
                                views={['year', 'month']} 
                                value={selectFilters.fullDataDate} 
                                onChange={(value) => handleOnChangeDataDate(value)}
                                slotProps={{
                                    actionBar: {
                                        actions: ['clear']
                                    }
                                }}
                                closeOnSelect={true}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
                <Stack direction="row" spacing={4}>
                    <Box display={'flex'} alignItems="center" >
                        <Checkbox
                            checked={checks.lastLoadChecked}
                            name="lastLoadCheck"
                            sx={{ height: '25px', width: '25px', 'ariaLabel': 'controlled' }}
                            onClick={handleChangeChecked}
                        />
                        <Typography sx={SELECT_TEXT}>
                            Utilizar últimos ficheros de entrada disponibles
                        </Typography>
                    </Box>
                    <Box display={'flex'} alignItems="center" >
                        <Checkbox
                            checked={checks.accountingChecked}
                            name="accountingCheck"
                            sx={{ height: '25px', width: '25px', 'ariaLabel': 'controlled' }}
                            onClick={handleChangeChecked}
                        />
                        <Typography sx={SELECT_TEXT}>
                            Utilizar fichero de Contabilidad
                        </Typography>
                    </Box>
                    <Box display={'flex'} alignItems="center" >
                        <Checkbox
                            checked={checks.mappingCNNSEpigraphChecked}
                            name="mappingCNNSEpigraphCheck"
                            sx={{ height: '25px', width: '25px', 'ariaLabel': 'controlled'}}
                            onClick={handleChangeChecked}
                        />
                        <Typography sx={SELECT_TEXT}>
                            Utilizar fichero de MapeoCCNSEpigrafe
                        </Typography>
                    </Box>
                </Stack>

                
                
                {!showEntryFilesTable() &&
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <StyledButton
                            onClick={() => {handleOnClickNext();}}
                            variant='contained'
                            endIcon={<ArrowCircleRight />}
                            disabled={isStepOneNotDone()}
                        >
                            Siguiente
                        </StyledButton>
                    </Box>
                }
            </Box>
            
            
            <Collapse in={showEntryFilesTable()}>
                <Box sx={{...CONTENT_BOX, mt: '0px', pb: '25px' }}> 
                    <Typography
                        color={BLUE}
                        sx={SUBTITLE_TEXT}
                    >
                        Selección de ficheros de entrada
                    </Typography>
                    {isBelowXlScreen ? (
                        <Box display={'flex'}>
                            <Typography
                                    sx={{...TITLE_TEXT, mt: '9px'}}
                                >
                                    {filteredFileTypes[selectedFileTypeTab]}
                            </Typography>
                            <IconButton sx={{ml: '10px', color: BLUE}} onClick={handleMenuOpen}>
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                            >
                                {filteredFileTypes.map((fileType, index) => 
                                    <MenuItem 
                                        key={index}
                                        onClick={() => handleMenuItemClick(index)}
                                        sx={{ color: selectedFileTypeTab === index ? BLUE : BLACK }}
                                    >
                                        {fileType}
                                    </MenuItem>
                                )}
                            </Menu>
                        </Box>
                    ) : (
                        <Tabs 
                            sx={{mb: '0px', minHeight: "38px", height: "38px", mt: '15px'}}
                            value={selectedFileTypeTab}
                            onChange={handleChangeTab}
                        >
                            {filteredFileTypes.map((fileType, index) => 
                                <AntTab
                                    key={index}
                                    tab={index}
                                    label={fileType}
                                />
                            )}
                        </Tabs>
                    )}
                    <Box>
                        <Grid container alignItems={'center'} sx={{mt: '10px'}}>
                            <Grid item xs={6} md={8}>
                            </Grid>
                            <Grid item xs={6} md={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button
                                    variant='contained'
                                    sx={{ fontSize: '13px', textTransform: 'none' }}
                                    endIcon={<FileUpload />}
                                    onClick={toggleUploadDialog}
                                >
                                    Subir Archivo
                                </Button>
                            </Grid>
                        </Grid>
                        <UploadDialog
                            openUploadDialog={openUploadDialog}
                            toggleUploadDialog={toggleUploadDialog}
                            fileType={filteredFileTypes[selectedFileTypeTab]}
                            fetchLoadData={fetchLoadData}
                            initialDate={selectFilters.fullDataDate}
                        />
                        <EntryFilesTable 
                            selectedEntryFile={selectedEntryFiles['id'+filteredFileTypes[selectedFileTypeTab]]}
                            handleOnClickRow={handleOnClickRow} 
                            entryFiles={entryFiles} 
                            setEntryFiles={setEntryFiles}
                            setPaginationData={setPaginationData} 
                            paginationData={paginationData} 
                            dataType={filteredFileTypes[selectedFileTypeTab]} 
                            selectFilters={selectFilters}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', pt: '26px', mb: '15px' }}>
                        <StyledButton
                            onClick={() => {handleOnClickNext();}}
                            variant='contained'
                            endIcon={<ArrowCircleRight />}
                            disabled={isStepOneNotDone()}
                        >
                            Siguiente
                        </StyledButton>
                    </Box>
                </Box>
            </Collapse>
        </Collapse >
    );
}