
import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

process.env.NODE_ENV === 'production' && Sentry.init({
    dsn: "https://e3677efa13b7e91bfa9391f02d6a6074@o4507508942045184.ingest.de.sentry.io/4507508947091536",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
        Sentry.replayIntegration({
          maskAllText: false, // Mascara el texto del replay
          blockAllMedia: false, // Bloquea todas las mostrar medios en el replay
        }),
        Sentry.breadcrumbsIntegration({
          console: true, // Captura mensajes de consola como breadcrumbs
          dom: true, // Captura eventos DOM (clicks, inputs)
          fetch: true, // Captura solicitudes fetch
          history: true, // Captura cambios de historial de navegación
          xhr: true, // Captura solicitudes XHR
        }),
    ],
    environment: process.env.NODE_ENV,
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "localhost", 
      /^https:\/\/dev\.solvencia\.alamoconsulting\.com\/front\/v0\//, // Rutas de la API
      /^https:\/\/dev\.solvencia\.alamoconsulting\.com\// // Rutas de la aplicación en producción
    ],
    // Session Replay
    replaysSessionSampleRate: 0.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
