import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
    auth: {
        clientId: import.meta.env.VITE_AUTHCONFIG_AUDIENCE,
        authority: import.meta.env.VITE_AUTHCONFIG_AUTHORITY,
        redirectUri: import.meta.env.VITE_AUTHCONFIG_REDIRECT_URI,
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: false,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        Sentry.captureException(new Error(message));
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        //console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            },
        },
        allowNativeBroker: false,
    },
};


export const loginRequest = {
    scopes: [import.meta.env.VITE_AUTHCONFIG_SCOPES]
}