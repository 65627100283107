import * as Sentry from "@sentry/react";
import { useApi } from "../hooks/useApi";
import { useErrors } from "../hooks/useErrors";

export function reportsCreditRiskService() {
    const { apiCall, addQueryParameters } = useApi();
    const { startLoading, stopLoading, setErrorState, clearError } = useErrors();

    const getCreditRiskReportSummary = async ({year, month, process_id}) => {
        const key = 'getCreditRiskReportSummary';
        var fileUrl = new URL(import.meta.env.VITE_API_URL + "/credit-risk-report/summary");
        var queryParams = {year, month, process_id};
        fileUrl = addQueryParameters(fileUrl, queryParams);
        startLoading(key);
        clearError(key);
        try {
            const response = await apiCall('GET', fileUrl);
            if (response.success) {
                return response.data;
            } else {
                setErrorState(key, "Error encontrando el resumen de riesgo de crédito.");
                Sentry.captureException(response, {
                    tags: { service: 'reportsCreditRiskService', key },
                    contexts: { apiCall: { url: fileUrl.toString(), method: 'GET' } },
                });
                return null;
            }
        } catch (error) {
            setErrorState(key, "Error encontrando el resumen de riesgo de crédito.");
            Sentry.captureException(error, {
                tags: { service: 'reportsCreditRiskService', key },
                contexts: { apiCall: { url: fileUrl.toString(), method: 'GET' } },
            });
            throw error;
        } finally {
            stopLoading(key);
        }
    };

    const getCreditRiskReportByEntity = async ({year, month, process_id}) => {
        const key = 'getCreditRiskReportByEntity';
        var fileUrl = new URL(import.meta.env.VITE_API_URL + "/credit-risk-report/by-entity");
        var queryParams = {year, month, process_id};
        fileUrl = addQueryParameters(fileUrl, queryParams);
        startLoading(key);
        clearError(key);
        try {
            const response = await apiCall('GET', fileUrl);
            if (response.success) {
                return response.data;
            } else {
                setErrorState(key, "Error encontrando el resumen de riesgo de crédito por entidad.");
                Sentry.captureException(response, {
                    tags: { service: 'reportsCreditRiskService', key },
                    contexts: { apiCall: { url: fileUrl.toString(), method: 'GET' } },
                });
                return null;
            }
        } catch (error) {
            setErrorState(key, "Error encontrando el resumen de riesgo de crédito por entidad.");
            Sentry.captureException(error, {
                tags: { service: 'reportsCreditRiskService', key },
                contexts: { apiCall: { url: fileUrl.toString(), method: 'GET' } },
            });
            throw error;
        } finally {
            stopLoading(key);
        }
    };

    return { getCreditRiskReportSummary, getCreditRiskReportByEntity };
}