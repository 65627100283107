import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import { useErrors } from '../hooks/useErrors';
import { BLUE, TITLE_TEXT } from '../theme';

const ErrorDialog = () => {
    const { dialogErrors, clearAllDialogErrors } = useErrors();

    const handleClose = () => {
        clearAllDialogErrors();
    };

    const hasErrors = Object.keys(dialogErrors).length > 0;

    return (
        <Dialog
            open={hasErrors}
            onClose={handleClose}
        >
            <DialogTitle
                color={BLUE}
                sx={TITLE_TEXT}
            >
                Error
            </DialogTitle>
            <DialogContent>
                {Object.values(dialogErrors).map((error, index) => (
                    <Typography key={index} >{error}</Typography>
                ))}
            </DialogContent>
            <DialogActions>
                <Button
                    variant='contained'
                    sx={{ fontSize: '13px', textTransform: 'none' }}
                    onClick={handleClose}
                >
                    Cerrar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ErrorDialog;