import React from 'react';
import ReactApexChart from 'react-apexcharts';

const HalfDonutChart = ({ title, seriesData, labels }) => {
    const options = {
        chart: {
            type: 'donut',
        },
        plotOptions: {
            pie: {
                donut: {
                    size: '50%',
                },
            },
        },
        labels: labels,
        dataLabels: {
            enabled: true,
            style: {
                fontSize: '16px',
                fontWeight: 'bold',
            },
        },
        legend: {
            position: 'bottom',
            horizontalAlign: 'center',
        },
        title: {
            text: title,
            align: 'center',
            style: {
                fontSize: '18px',
                fontWeight: 'bold',
            },
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val + ' unidades';
                },
            },
        },
    };

    return (
        <div id="chart">
            <ReactApexChart options={options} series={seriesData} type="donut" height={400} />
        </div>
    );
};

export default HalfDonutChart;
