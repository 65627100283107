import React from 'react';
import ReactApexChart from 'react-apexcharts';

const BarLineChart = ({ categories, exposicionData, APRsData, ponderacionMediaData }) => {
    const series = [
        {
            name: 'Exposición',
            type: 'bar',
            data: exposicionData
        },
        {
            name: 'APRs',
            type: 'bar',
            data: APRsData
        },
        {
            name: 'Ponderación Media',
            type: 'line',
            data: ponderacionMediaData
        }
    ];

    const options = {
        chart: {
            type: 'line',
            stacked: false
        },
        plotOptions: {
            bar: {
                columnWidth: '50%'
            }
        },
        stroke: {
            width: [0, 0, 4]
        },
        // title: {
        //     text: 'Exposición, APRs y Ponderación Media por Categoría',
        //     style: {
        //         fontSize: '18px',
        //         fontWeight: 'bold',
        //     },
        // },
        xaxis: {
            categories: categories,
            labels: {
                rotate: -30,
                style: {
                    fontSize: '12px'
                }
            }
        },
        yaxis: [
            {
                axisTicks: {
                    show: true
                },
                axisBorder: {
                    show: true,
                },
            },
            {
                opposite: true,
                axisTicks: {
                    show: true
                },
                axisBorder: {
                    show: true,
                },
            }
        ],
        tooltip: {
            shared: true,
            intersect: false
        },
        legend: {
            horizontalAlign: 'center',
            position: 'top',
            offsetX: 40
        },
        grid: {
            padding: {
                bottom: 30 // Aumenta este valor para dar más espacio a las etiquetas del eje x
            }
        }
    };

    return (
        <div id="chart">
            <ReactApexChart options={options} series={series} type="line" height={400} />
        </div>
    );
};

export default BarLineChart;
