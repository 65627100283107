import * as Sentry from "@sentry/react";
import { useApi } from "../hooks/useApi";
import { useErrors } from "../hooks/useErrors";

export function processesService() {
    const { apiCall, addQueryParameters } = useApi();
    const { startLoading, stopLoading, setErrorState, clearError } = useErrors();

    const getProcesses = async ({filters, limit, start}) => {
        const key = 'getProcesses';
        var fileUrl = new URL(import.meta.env.VITE_API_URL + "/execution/report");
        var queryParams = {...filters, limit: limit, start: start};
        fileUrl = addQueryParameters(fileUrl, queryParams);
        startLoading(key);
        clearError(key);
        try {
            const response = await apiCall('GET', fileUrl);
            if (response.success) {
                return response.data;
            } else {
                setErrorState(key, "Error encontrando los procesos.");
                Sentry.captureException(response, {
                    tags: { service: 'processesService', key },
                    contexts: { apiCall: { url: fileUrl.toString(), method: 'GET' } },
                });
                return null;
            }
        } catch (error) {
            setErrorState(key, "Error encontrando los procesos.");
            Sentry.captureException(error, {
                tags: { service: 'processesService', key },
                contexts: { apiCall: { url: fileUrl.toString(), method: 'GET' } },
            });
            throw error;
        } finally {
            stopLoading(key);
        }
    };

    return { getProcesses };
}
