import * as Sentry from "@sentry/react";
import { useApi } from "../hooks/useApi";
import { useErrors } from "../hooks/useErrors";

export function executionProcessService() {
    const { apiCall, addQueryParameters } = useApi();
    const { startLoading, stopLoading, setDialogErrorState, clearDialogError } = useErrors();

    const postExecutionProcess = async ({ processType, runVersion, month, year, lastLoad, MapeoCCNSEpigrafe, Contabilidad, specific_files }) => {
        const key = 'postExecutionProcesss';
        var fileUrl = new URL(import.meta.env.VITE_API_URL + "/execution/process");
        var queryParams = { processType, runVersion, month, year, lastLoad, MapeoCCNSEpigrafe, Contabilidad };
        fileUrl = addQueryParameters(fileUrl, queryParams);
        const payload = {specific_files}
        startLoading(key);
        clearDialogError(key);
        try {
            const response = await apiCall('POST', fileUrl, payload);
            if (!response.success) {
                setDialogErrorState(key, "Error ejecutando el proceso.");
                Sentry.captureException(response, {
                    tags: { service: 'executionProcessService', key },
                    contexts: { apiCall: { url: fileUrl.toString(), method: 'POST' } },
                });
                return null;
            }
        } catch (error) {
            setDialogErrorState(key, "Error ejecutando el proceso.");
            Sentry.captureException(error, {
                tags: { service: 'executionProcessService', key },
                contexts: { apiCall: { url: fileUrl.toString(), method: 'POST' } },
            });
            throw error;
        } finally {
            stopLoading(key);
        }
    }

    return { postExecutionProcess }
}