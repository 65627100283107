import * as Sentry from "@sentry/react";
import { useApi } from "../hooks/useApi";
import { useErrors } from "../hooks/useErrors";

export function parametrizationService() {
    const { apiCall, addQueryParameters } = useApi();
    const { startLoading, stopLoading, setDialogErrorState, clearDialogError } = useErrors();

    const getParametrization = async ({parameterCode, screenId}) => {
        const key = 'getParametrization';
        var fileUrl = new URL(import.meta.env.VITE_API_URL + "/parametrization");
        var queryParams = {parameterCode: parameterCode, screenId: screenId};
        fileUrl = addQueryParameters(fileUrl, queryParams);
        startLoading(key);
        clearDialogError(key);
        try {
            const response = await apiCall('GET', fileUrl);
            if (response.success) {
                return response.data;
            } else {
                setDialogErrorState(key, "Error encontrando la parametrización.");
                Sentry.captureException(response, {
                    tags: { service: 'parametrizationService', key },
                    contexts: { apiCall: { url: fileUrl.toString(), method: 'GET' } },
                });
                return null;
            }
        } catch (error) {
            setDialogErrorState(key, "Error encontrando la parametrización.");
            Sentry.captureException(error, {
                tags: { service: 'parametrizationService', key },
                contexts: { apiCall: { url: fileUrl.toString(), method: 'GET' } },
            });
            throw error;
        } finally {
            stopLoading(key);
        }
    }

    return { getParametrization }
}