import React from 'react';
import { Chart as ChartJS, LinearScale, CategoryScale, BarElement, PointElement, 
  LineElement, Legend, Tooltip, LineController, BarController } from 'chart.js';
import { Chart } from 'react-chartjs-2';
import faker from 'faker';

  
ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

const terms = [
  '2019 T1',
  '2019 T2',
  '2019 T3',
  '2019 T4',
  '2020 T1',
  '2020 T2',
  '2020 T3',
  '2020 T4',
  '2021 T1',
  '2021 T2',
  '2021 T3',
  '2021 T4',
  '2022 T1',
  '2022 T2',
  '2022 T3',
  '2022 T4',
]

const data = (totalRiskExposure, cet1Ratio, tier1Ratio, totalCapitalRatio) => {
  return {
    labels: terms,
    datasets: [
      {
          yAxisID: "right-y-axis",
          type: 'line',
          label: 'Ratio de CET1',
          borderColor: 'rgb(0, 0, 255)',
          borderWidth: 2,
          fill: false,
          data: cet1Ratio,
      },
      {
          yAxisID: "right-y-axis",
          type: 'line',
          label: 'Ratio de tier 1',
          borderColor: 'rgb(255, 165, 0)',
          borderWidth: 2,
          fill: false,
          data: tier1Ratio,
      },
      {
          yAxisID: "right-y-axis",
          type: 'line',
          label: 'Ratio de capital total',
          borderColor: 'rgb(255, 0, 0)',
          borderWidth: 2,
          fill: false,
          data: totalCapitalRatio,
      },
      {
          yAxisID: "left-y-axis",
          type: 'bar',
          label: 'Importe de la exposicion total al riesgo',
          backgroundColor: 'rgb(53, 162, 235)',
          data: totalRiskExposure,
          borderColor: 'white',
          borderWidth: 2,
      },
    ],
  }
};

const options = {
  maintainAspectRatio: false,
  scales: {
    "left-y-axis": {
      type: "linear",
      position: "left",
      grid: {
        borderDash: [8, 6],
        lineWidth: 2
      },
      ticks: {
        maxTicksLimit: 6
      }
    },
    "right-y-axis": {
      type: "linear",
      position: "right",
      grid: {
        display: false
      },
      ticks: {
        callback: (v) => v + "%",
        maxTicksLimit: 6
      }
    }
  }
};



export default function CapitalRatiosEvolutionGraph(props) {
  const totalRiskExposure = terms.map(() => faker.datatype.number({ min: 1400, max: 1600 }))
  const cet1Ratio = terms.map(() => parseFloat((faker.datatype.number({ min: 12, max: 14 }) + Math.random()).toFixed(2)))
  const tier1Ratio = terms.map(() => parseFloat((faker.datatype.number({ min: 14, max: 15 }) + Math.random()).toFixed(2)))
  const totalCapitalRatio = terms.map(() => parseFloat((faker.datatype.number({ min: 15, max: 18 }) + Math.random()).toFixed(2)))
  
  return <Chart 
              type='bar' 
              data={data(totalRiskExposure, cet1Ratio, tier1Ratio, totalCapitalRatio)} 
              height="100%"
              width="100%"
              options={options}
          />;
}